import { Component, OnInit ,ViewEncapsulation,isDevMode} from '@angular/core';
import {TablaFiltroComponent} from '../tabla-filtro/tabla-filtro.component';
import {TablaService} from '../services/tabla.service';
import {DetalleExpedienteService} from '../services/detalle-expediente.service';
import {FiltersLegajoItem} from '../interfaces/filtersLegajo';
import {InputTextModule} from 'primeng/inputtext';
interface Organismo {
  name: string,
  code: string
}
interface Letra {
  name: string,
  code: string
}
@Component({
  selector: 'app-consulta-legajo',
  templateUrl: './consulta-legajo.component.html',
  styleUrls: ['./consulta-legajo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})


export class ConsultaLegajoComponent implements OnInit {
  organismos: Organismo[];
  etapas:Organismo[];
  procesos:Organismo[];
  selectedOrganismo: Organismo;
  selectedEtapa:Organismo;
  selectedProceso:Organismo;
  numeroExpediente:string=undefined;

  anio:string=undefined;
  caratulaExpediente:string;
  numeroAnteriorExpediente:string;
  filterParams:FiltersLegajoItem;
  origin='legajo';
  letras:Letra[];
  selectedLetra:Letra={name: 'P', code: 'P'};

  constructor(private tablaService:TablaService,
    private detalleExpendienteService:DetalleExpedienteService
    ) {
    this.organismos = [
      {name: 'Organismo1', code: 'NY'},
      {name: 'Organismo2', code: 'RM'},
      {name: 'Organismo3', code: 'LDN'},
      {name: 'Organismo4', code: 'IST'},
      {name: 'Organismo5', code: 'PRS'}
  ];
  this.letras = [
    {name: 'P', code: 'P'},
    {name: 'T', code: 'T'},
    {name: 'Q', code: 'Q'},
    {name: 'E', code: 'E'},
    {name: 'HC', code: 'HC'},
    {name: 'IP', code: 'IP'},
    {name: 'I', code: 'I'},
    {name: 'SC', code: 'SC'},
    {name: 'R', code: 'R'},
   
];
// this.organismos=this.detalleExpendienteService.getOrganismos()
// this.etapas=this.detalleExpendienteService.getEtapas()
// this.procesos=this.detalleExpendienteService.getProcesos()

   }

   expedienteLookup()
   {
     if(isDevMode()) console.log("looking up")
     if(isDevMode()) console.log(this.caratulaExpediente);
     let tempFilterParams= new FiltersLegajoItem();
     let inputMissing=false
    


     
     if (this.numeroExpediente!==undefined){tempFilterParams.numero=this.numeroExpediente}else{inputMissing=true}
     if (this.selectedLetra!==undefined){tempFilterParams.letra=this.selectedLetra.code}else{inputMissing=true}
     if (this.anio!==undefined && this.anio.length==4){tempFilterParams.anio=this.anio.substr(2,3)}else{inputMissing=true}
     //if (this.caratulaExpediente!==undefined){tempFilterParams.caratula=this.caratulaExpediente}
     //if (this.selectedOrganismo!==undefined){tempFilterParams.organismo=this.selectedOrganismo}
     //if (this.selectedProceso!==undefined){tempFilterParams.proceso=this.selectedProceso}
     //if (this.numeroAnteriorExpediente!==undefined){tempFilterParams.numeroContiene=this.numeroAnteriorExpediente}
     //if (this.selectedEtapa!==undefined){tempFilterParams.etapa=this.selectedEtapa}

     
   
     //trigger change
    
     if(inputMissing==false){
      if(isDevMode()) console.log("updating tempfilters");
        this.filterParams=tempFilterParams
      }else
      {

      }






    //  this.tablaLegajoService.getExpedientes(this.filterParams).subscribe(r=>
    //   {
    //     if(isDevMode()) console.log(r)
        
    //   })
   }
   clearFilters()
   {
     this.numeroExpediente=undefined;
     this.caratulaExpediente=undefined;
     this.selectedOrganismo=undefined;
     this.selectedProceso=undefined;
     this.numeroAnteriorExpediente=undefined;
     this.selectedEtapa=undefined;
     this.anio=undefined;
     this.numeroExpediente=undefined;
   }

  ngOnInit(): void {
  }

}
