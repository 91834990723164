import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, of } from 'rxjs';
import {TablaItem} from '../interfaces/tablaItem';


@Injectable({
  providedIn: 'root'
})
export class DetalleExpedienteService {

  constructor(private http:HttpClient) { }

  // apirest/doc(?P<format>\.json|\.yaml)$ [name='schema-json']
  // apirest/doc/ [name='schema-swagger-ui']
  // apirest/redoc/ [name='schema-redoc']
  // redapp/
  // alertas/
  // audiencias/
  // apirest/ v1/Organismos/id/<id>
  // apirest/ v1/Fiscal/id/<id>
  // apirest/ v1/UnidadFiscal/id/<id>
  // apirest/ v1/Fiscalia/id/<id>
  // apirest/ v1/Asesoria/id/<id>
  // apirest/ v1/Defensor/id/<id>
  // apirest/ v1/CategoriasRepositorios/id/<id>
  // apirest/ v1/AccionExpedientes/id/<id>
  // apirest/ v1/Usuario/id/<id>
  // apirest/ v1/User/id/<id>
  // apirest/ v1/Expedientes/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Expedientes/Id/<int:pk>
  // apirest/ v1/Expedientes/FechaCreacion/<gt_fecha_creacion>/<lt_fecha_creacion>
  // apirest/ v1/Expedientes/FechaCreacionOrganismo/<gt_fecha_creacion>/<lt_fecha_creacion>/<organismo>
  // apirest/ v1/Alertas/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/LegajoAsociado/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/DatosCondena/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/SentenciaEjecucion/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/MontosPena/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/DefensorTecnico/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Repositorio/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/RepositorioPersonas/IdPersona/<persona>
  // apirest/ v1/RepositorioPersonas/IdActuacion/<actuacion>
  // apirest/ v1/Magistrado/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Delito/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Hechos/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/NotasPublicas/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/DatosHC/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Sentencias/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/ResponsableTrack/<letra>/<numero_expediente>/<anio>
  // apirest/ v1/Personas/TipoNroDoc/<tipo_documento>/<numero_documento>/
  // apirest/ v1/Personas/id/<id>/
  // apirest/ v1/Personas/numero/<numero>/
  // apirest/ v1/Rol/<letra>/<numero_expediente>/<anio>
  // personas/
  // repositorio/
  ///Repositorio/{letra}/{numero_expediente}/{anio}
  // tareas/
  // videos/
  // reportes/
  // resoluciones/
  // condenas/
  // [name='index']
  // nueva_version/ [name='nueva_version']
  // accounts/login/ [name='login']
  // logout/ [name='logout']



  getInformacionGeneral(input): Observable<any[]>
  {
    //expecting to receive something like this 'P/64793/20'
    ///legajo/{letra}/{numero_expediente}/{anio} 
    console.log(input)

    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/legajo/';
   return  this.http.get<any[]>(server+segment_head+input+segment_tail)
  }

  getTareas(): Observable<any[]>
  {

    const server =  environment.casos;
   return  this.http.get<any[]>(server)
  }

  getAcusacion(): Observable<any[]>
  {

    const server =  environment.casos;
   return  this.http.get<any[]>(server)
  }

  getDefensaTecnica(): Observable<any[]>
  {

    const server =  environment.casos;
   return  this.http.get<any[]>(server)
  }

  //-------Audiencias paginadas
  getAudienciasFuturas(expte, page, fechaActual, offset)
  {   
    const fechaDesde = fechaActual;
    const fechaHasta = '01-01-2085';
    const pag = page;
    const cantidadPorPag = offset;

    const server = environment.audiencias_api;
    const segment_tail= expte + '/'+ fechaDesde + '/' + fechaHasta + '/'+pag+'/'+cantidadPorPag;
    const segment_head='/api/v2/agenda/';
    
    return this.http.get<any[]>(server+segment_head+segment_tail)     
  }
  
  getAudienciasPasadas(expte, page, fechaActual, offset)
  {    
    const fechaDesde = '01-01-2015';
    const fechaHasta = fechaActual;
    const pag = page;
    const cantidadPorPag = offset;

    const server = environment.audiencias_api;
    const segment_tail= expte + '/'+ fechaDesde + '/' + fechaHasta + '/'+pag+'/'+cantidadPorPag;
    const segment_head='/api/v2/agenda/';
    
    return this.http.get<any[]>(server+segment_head+segment_tail) 
    
  }

  
  getAudiencias(expte): Observable<any[]>
  {
    const server = environment.audiencias_api;
    const segment_tail= '?expte=';
    const segment_head='/expte_api1';
    return  this.http.get<any[]>(server+segment_head+segment_tail+expte)
    
  }
  getHechos(input)
  { 
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Hechos/';
   return  this.http.get<any[]>(server+segment_head+input+segment_tail)
  }
  getDelitos(input)
  { 
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Delito/';
   return  this.http.get<any[]>(server+segment_head+input+segment_tail)
  }
  getImputados()
  { 
    const server =  environment.casos;
    return  this.http.get<any[]>(server)
  }
  getVictimas()
  { 
    const server =  environment.casos;
    return  this.http.get<any[]>(server)
  }
  getPeritos()
  { 
    const server =  environment.casos;
    return  this.http.get<any[]>(server)
  }

  getMagistrado(input):Observable<any[]>
  {
   
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Magistrado/';
   return  this.http.get<any[]>(server+segment_head+input+segment_tail)
  }

  getRol(input):Observable<any[]>
  {
   
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Rol/';
   return  this.http.get<any[]>(server+segment_head+input+segment_tail)
  }

  getRepositorio(input): Observable<any[]>{
    input = 5195
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Expedientes/id/';
    ///Expedientes/Id/{id}
    return  this.http.get<any[]>(server+segment_head+input+segment_tail)  
  }
  
  getDefensorTecnico(input): Observable<any[]>{
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/DefensorTecnico/';
    return  this.http.get<any[]>(server+segment_head+input+segment_tail)  
  }

  getPersonaNro(nro): Observable<any[]>{
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/Personas/numero/';
    return  this.http.get<any[]>(server+segment_head+nro+segment_tail)
  }

    // apirest/ v1/RepositorioPersonas/IdPersona/<persona>
    getPersonaId(id): Observable<any[]>{
      const server =  environment.led_api;
      const segment_tail= '?format=json';
      const segment_head='/Personas/id/';
      return  this.http.get<any[]>(server+segment_head+id+segment_tail)
    }

  getRepoPersonaId(persona:string): Observable<any[]>{
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/RepositorioPersonas/IdPersona/';
    return  this.http.get<any[]>(server+segment_head+persona+segment_tail)
  }


  getResponsable(input): Observable<any[]>{
    const server =  environment.led_api;
    const segment_tail= '?format=json';
    const segment_head='/ResponsableTrack/';    
    return  this.http.get<any[]>(server+segment_head+input+segment_tail)  
  }


  getOrganismos()
  {

    let organismosMock=[{
      "name":"JPC1",
      "code":"1"
    },
    {
      "name":"JPC T",
      "code":"2"
    },
    {
      "name":"TPC SR",
      "code":"3"
    },
    {
      "name":"JPC SR",
      "code":"4"
    }]

return organismosMock;

  }

  getProcesos()
  {
    let procesosMock=[{
      "name":"Correccional",
      "code":"1"
    },
    {
      "name":"Recurso",
      "code":"2"
    },
    {
      "name":"Recursos",
      "code":"3"
    }
    ]
return procesosMock;
  }

getEtapas()
{
    let etapasMock=[{
    "name":"En trámite",
    "code":"1"
    },
    {
    "name":"Resuelto",
    "code":"2"
    },
    {
    "name":"Archivado",
    "code":"3"
    }
    ]
return etapasMock;
}

getAccionExpedientes(id): Observable<any[]>{
  const server =  environment.led_api;
  const segment_tail= '?format=json';
  const segment_head='/AccionExpedientes/id/';
  return  this.http.get<any[]>(server+segment_head+id+segment_tail)  
}

getVinculados(filters): Observable<any[]>{
  let filtersFinalString = filters.letra+'/'+filters.numero+'/'+filters.anio
  const server = environment.led_api;
  const segment_tail = '?format=json';
  const segment_head = '/legajosdependientes/'
  return  this.http.get<any[]>(server+segment_head+filtersFinalString+segment_tail)

}

}
