export const environment = {
  production: true,
  casos: "https://run.mocky.io/v3/cb2fb2b7-e287-4af1-ae6a-abee27b381ad",
  led_api: "https://pled.pjm.gob.ar/apirest/v1",
  //audiencias_api: "https://hwapis.pjm.gob.ar/audiencias-api/api/expte_api.php",
  audiencias_api: "https://api.audienciaspenal.pjm.gob.ar",
  KC_URL: "https://auth24.pjm.gob.ar/auth/",
  KC_CLIENT_ID: "consulta-led",
  KC_REALM: "registered",
  LOGIN_HOME_ENCODED: "http%3A%2F%2Flocalhost%3A4603",
};
