import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private http:HttpClient) { }


  verifyToken(token)
  {
    const decodedToken = helper.decodeToken(token);
    const expirationDate = helper.getTokenExpirationDate(token);
    const isExpired = helper.isTokenExpired(token);

    if(decodedToken=='something')
    {return true}else{return false}
  }

  logout()
  {
    //https://auth.pjm.gob.ar/auth/realms/master/protocol/openid-connect/logout?redirect_uri=
    console.log("inside logout service")
    let logout_url=environment.KC_URL + '/auth/realms/' + environment.KC_REALM + '/protocol/openid-connect/logout?redirect_uri=' + environment.LOGIN_HOME_ENCODED
    return  this.http.get<any[]>(logout_url)
  
  }
}
