import { Component, OnInit } from '@angular/core';
import {faSearch } from '@fortawesome/free-solid-svg-icons';
import {BotoneraBusquedaComponent} from '../botonera-busqueda/botonera-busqueda.component';
import {SessionService} from '../services/session.service'
import { TablaService } from '../services/tabla.service';
import {HostListener,Directive,HostBinding,Input} from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();
@Component({
  selector: 'app-menu-bars',
  templateUrl: './menu-bars.component.html',
  styleUrls: ['./menu-bars.component.scss']
})
export class MenuBarsComponent implements OnInit {
  
  faSearch=faSearch;
  currentSearchChildComponent='número';
  userName=''
  constructor(private sessionService:SessionService, private tablaService:TablaService) {
    console.log('constructor')
  
   }
  
   @HostBinding('class.is-open')
   detailShow=false;
 

  ngOnInit(): void {
    this.sessionService.getStateSessionData().subscribe(data=>
      {
        console.log(data)
        console.log("data loaded!!!")
        console.log(this.sessionService.getSessionData().then(d=>{
          const decodedToken = helper.decodeToken(d);
          console.log(decodedToken)
          this.userName=decodedToken.preferred_username
          console.log(this.userName)
        }))

      })
     this.tablaService.change.subscribe(isOpen => {
      this.detailShow = isOpen;
      console.log(this.detailShow)
    })
    
    console.log('inicio ' + this.detailShow) 
  }

  
  
  buttonSearchSelected(event)
  {
    //this.detailShow=true;
    console.log("FROM PARENT")
    console.log(event)
    if(event == 'legajo'){
      this.currentSearchChildComponent='número'
    }else{
      this.currentSearchChildComponent=event
    } 
  }

  logout()
  {
    this.sessionService.logout()
  }

  showButtons(){
    this.currentSearchChildComponent='número';    
    console.log('ocultarrrrrrr botones')
    this.detailShow=false;
  }

}
