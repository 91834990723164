<div class="card card-busqueda" *ngIf="tableLoaded" id="flip-scroll">    

<div class="row" >  
          
        <p-table
        [value]="tablaItems"
        [paginator]="true"
        [rows]="10"
        [showCurrentPageReport]="true" 
        currentPageReportTemplate="{first} a {last} de un total de {totalRecords} filas"
        [rowsPerPageOptions]="[10,25,50]"
        [loading]="loadingTable"
        class="myTable table table-bordered table-striped"
        >
        
            <ng-template pTemplate="header">
                <tr class="p-column-title">
                    <th>Numero</th>
                    <th>Caratula</th>
                    <th>Organismo</th>
                    <th>Proceso</th>
                    <th>Etapa</th>
                    <th>Creacion</th>
                    <th>Accion</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.numero}}</td>
                    <td>{{item.caratula}}</td>
                    <td>{{item.organismo}}</td>
                    <td>{{item.proceso}}</td>
                    <td>{{item.etapa}}</td>
                    <td>{{item.fecha_creacion}}</td>
                    <td class="justify-content-center">

                        <button 
                                    class="btn btn-outline-primary" 
                                    (click)="navigate2Detail(item.anio,item.letra,item.numero_expediente)" 
                                    style="align-items: center;">
                                    Ver
                        </button>
                        
<!--                             <div class="vertical-center-button">
                                <button 
                                    class="btn btn-outline-primary" 
                                    (click)="navigate2Detail(item.anio,item.letra,item.numero_expediente)" 
                                    style="align-items: center;">
                                    Ver
                                </button>
                            </div> -->
                        
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>




</div>