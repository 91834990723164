import { Component, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-botonera-busqueda',
  templateUrl: './botonera-busqueda.component.html',
  styleUrls: ['./botonera-busqueda.component.scss']
})
export class BotoneraBusquedaComponent implements OnInit {
  @Output() buttonSelectedEvent = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  buttonSelected(value: string) {
    this.buttonSelectedEvent.emit(value);
  }

}
