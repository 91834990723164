import { Injectable } from '@angular/core';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  render=[]
  num_exp=''
  tree=[]
  constructor() { }


  saveRender(render,num_exp)
  
  {
    this.render=render
    this.num_exp=num_exp
    console.log("render saved (service)"+num_exp)
  }

  saveTree(tree)
  {
this.tree=tree
  }

  getRender()
  {
    return this.render
  }

  getNumExp()
  {
    return this.num_exp
  }
  getTree()
  {
    return this.tree
  }
}
