import { Component, OnInit , Input, OnChanges } from '@angular/core';
import {TablaItem} from '../interfaces/tablaItem';
import {CasosService} from '../services/casos.service';
import {TablaService} from '../services/tabla.service';
import {FiltersLegajoItem} from  './../interfaces/filtersLegajo';
import {ActivatedRoute, Router} from '@angular/router';
import { threadId } from 'worker_threads';


@Component({
  selector: 'app-tabla-filtro',
  templateUrl: './tabla-filtro.component.html',
  styleUrls: ['./tabla-filtro.component.scss']
})
export class TablaFiltroComponent implements OnInit {
  @Input() filters ;
  @Input() origin ;
  
  tablaItems:TablaItem[];

  //@HostListener('navigate2Detail')
 
  loadingTable=false;
  filtersLegajoItem:FiltersLegajoItem
  tableLoaded: boolean = false;

  constructor(private casosService:CasosService,
    private tablaService:TablaService,
    private route: ActivatedRoute,
    private router: Router) {
  //   this.tablaItems=[{"numero":"10020","caratula":"Perez/Gonzalez","organismo":"JPC1","proceso":"Correcional","etapa":"en tramite","creacion":"20/02/2020"},
  //   {"numero":"12020","caratula":"Jacinto/Luquez","organismo":"JPC1","proceso":"Correcional","etapa":"en tramite","creacion":"20/02/2020"},
  //   {"numero":"13020","caratula":"Cassara/Perin","organismo":"JPC1","proceso":"Ejecucion","etapa":"en tramite","creacion":"20/02/2020"},
  //   {"numero":"14020","caratula":"Lucas/Cordera","organismo":"JPC1","proceso":"Correcional","etapa":"en tramite","creacion":"20/02/2020"}]
    } 

    ngOnChanges()
     {
       console.log(this.tableLoaded)
       this.loadingTable=true;

       if (this.filters!==undefined)
       {
        console.log("from tabla component");
        console.log(this.filters )
        console.log(this.origin)
        this.tablaService.getExpedientes(this.origin,this.filters).subscribe(
          r=>
            {
                console.log("number of items  from back");
                this.tableLoaded = true;

                if (this.origin == 'legajo'){
                    this.tablaItems=r['Expediente'];
                    console.log("tabla"+this.tablaItems);
                }else{                               
                    let roles = r['Roles'];                
                    this.tablaItems = [];

                    roles.forEach(rol => { 
                      let item:TablaItem = rol.numero
                      this.tablaItems.push(item);                                                       
                    });          
                            
                }
            
            this.loadingTable=false;
           
          },
         
          error=>{
            alert("Se ha producido un error en el servidor, por favor llame a informática")
            console.log("error de servidor")
            console.log(error);
            this.loadingTable=false;
            
          }
          
          )
       }
    
     }

  ngOnInit(): void {

    this.tableLoaded = false;



    // this.casosService.getCasos().subscribe(r=>
    //   {
    //     this.tablaItems=r['data']
    //   })
  }

  navigate2Detail(anio,letra,numero_expediente)
  {
    //this.tablaService.toggle()
    //this.router.navigate(['/dash/detalle-expediente',letra,numero_expediente,anio,this.origin]);

    const url = this.router.serializeUrl(this.router.createUrlTree(['/dash/detalle-expediente',letra,numero_expediente,anio,this.origin]));
    window.open(url, '_blank');  
  }

}
