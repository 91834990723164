import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuBarsComponent } from './menu-bars/menu-bars.component';
import { TimelineComponent } from './timeline/timeline.component';
import { ConsultaLegajoComponent } from './consulta-legajo/consulta-legajo.component';
import { ConsultaPersonaComponent } from './consulta-persona/consulta-persona.component';
import { TablaFiltroComponent } from './tabla-filtro/tabla-filtro.component';
import { DetalleExpedienteComponent } from './detalle-expediente/detalle-expediente.component';
import {CasosService} from './services/casos.service';
import {HttpClientModule} from '@angular/common/http'; 
import { PrimeNGModule } from './primeng';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BotoneraBusquedaComponent } from './botonera-busqueda/botonera-busqueda.component';
import {InputMaskModule} from 'primeng/inputmask';
import { APP_INITIALIZER } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import {environment} from './../environments/environment';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PdfviewerComponent } from './pdfviewer/pdfviewer.component';
import { ActuacionesDigitalizadasComponent } from './actuaciones-digitalizadas/actuaciones-digitalizadas.component'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {RolInterceptor} from './interceptor/rol-interceptor';
import {SecurityService} from './services/security.service';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DocumentService} from './services/document.service';
import {InputTextModule} from 'primeng/inputtext';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import {TreeModule} from 'primeng/tree';
import{SessionService} from './services/session.service'
import { Session } from 'inspector';
function initializeKeycloak(keycloak: KeycloakService,sessionService:SessionService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.KC_URL,
        realm: environment.KC_REALM,
        clientId: environment.KC_CLIENT_ID,
      },
      initOptions: {
        onLoad: 'check-sso'
      },
    }).then(r=>{
      sessionService.hasSessionActive(true)
      console.log("logged")
    }
      
    );
}

// function initializeKeycloak(keycloak: KeycloakService) {
//   return () =>
//     keycloak.init({
//       config: {
//         url: 'http://localhost:8080/auth',
//         realm: 'your-realm',
//         clientId: 'your-client-id',
//       },
//       initOptions: {
//         onLoad: 'check-sso',
//         silentCheckSsoRedirectUri:
//           window.location.origin + '/assets/silent-check-sso.html',
//       },
//     });
// }

@NgModule({
  declarations: [
    AppComponent,
    MenuBarsComponent,
    TimelineComponent,
    ConsultaLegajoComponent,
    ConsultaPersonaComponent,
    TablaFiltroComponent,
    DetalleExpedienteComponent,
    BotoneraBusquedaComponent,
    PdfviewerComponent,
    ActuacionesDigitalizadasComponent,
    NotAllowedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PrimeNGModule,    
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule, 
    PdfViewerModule,
    KeycloakAngularModule,
    InputMaskModule,
    NgxMaskModule.forRoot(),
    TreeModule,
    ProgressSpinnerModule,
    InputTextModule
    
    
  ],
  providers: [CasosService,
    {
    provide: APP_INITIALIZER,
    useFactory: initializeKeycloak,
    deps: [KeycloakService,SessionService],
    multi: true
   
  },
  {

    provide: HTTP_INTERCEPTORS,
    useClass: RolInterceptor,
     multi: true 
    
  },
  SecurityService,
  DocumentService
],
  entryComponents: [AppComponent],
  bootstrap: [AppComponent],
 

  
 
})
export class AppModule {

 

 }


