import { NgModule } from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {TabViewModule} from 'primeng/tabview';
import {KeyFilterModule} from 'primeng/keyfilter';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {Panel, PanelModule} from 'primeng/panel';
import {ScrollTop, ScrollTopModule} from 'primeng/scrolltop';




@NgModule({
    imports: [
        TableModule,
        ButtonModule,
        DropdownModule,
        CardModule,
        InputTextModule,
        TabViewModule,
        KeyFilterModule,
        DialogModule,
        DividerModule,
        PanelModule,
        ScrollTopModule
         
    ],

    exports: [
        TableModule,
        ButtonModule,
        DropdownModule,
        CardModule,
        InputTextModule,
        TabViewModule,
        KeyFilterModule,
        DialogModule,
        DividerModule,
        PanelModule,
        ScrollTopModule
        
    ],
    providers: [
    ]

})

export class PrimeNGModule {}