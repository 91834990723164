import { Component, OnInit,Input, OnChanges, SimpleChanges  } from '@angular/core';
import { isDevMode } from '@angular/core';
import { faIndent} from '@fortawesome/free-solid-svg-icons';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {StorageService} from './../services/storage.service';
import {
  Doc
} from './../interfaces/docs';
import { Observable, forkJoin } from 'rxjs';
import {
  PDFJSStatic,
  PDFPageViewport,
  PDFRenderTask,
  PDFDocumentProxy,
  PDFPageProxy,
} from 'pdfjs-dist';
import _ from "lodash";
//const PDFJS: PDFJSStatic = require('pdfjs-dist');
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
declare var $: any;
pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/js/pdf.worker.js';
import {DocumentService} from './../services/document.service';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import {map, catchError, subscribeOn} from 'rxjs/operators';

import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import {of} from 'rxjs';
import {DialogModule} from 'primeng/dialog';


//declare var require: any
@Component({
  selector: 'app-pdfviewer',
  templateUrl: './pdfviewer.component.html',
  styleUrls: ['./pdfviewer.component.scss']
})
export class PdfviewerComponent implements OnInit,OnChanges {
  faIndent = faIndent;
  @Input() master = {};
  showClassicView=false;
  showMagazineView=false;
  showNewMagazineView=false;
  myImage1: any
  img: any
  displayBookDialog: boolean = false;
  vw = ''
  vh = ''
  vw_half = ''
  numPages = 0
  full_pics = []
  stickyIndex=false
  
  
  global_pages_counter=0
  global_document_counter=0
  global_temp_page_counter=0
  index_counter=0

  currentPagesPair=[]
  firstStart=true;
  global_activity=0
  displayWorkingDialog=false


  //API
  IDS_expediente=[]
  DownloadedB64Docs=[]

  num_exp=''

  treeData=[
        {
            "label": "Indice",
            "data": "Documents Folder",
            "expandedIcon": "pi pi-folder-open",
            "collapsedIcon": "pi pi-folder",
            "children": []
        }
    ]

  
  

  //docs=['https://s.pjm.gob.ar/meed/?short_id=KZCHN142135','https://s.pjm.gob.ar/meed/?short_id=YHCOJ191948']
  docs: Doc[] = []
  loadingTaskArr=[]

  constructor(
    private documentService:DocumentService,
    private storageService:StorageService
    ) {
    this.docs = [{
        'link': 'https://s.pjm.gob.ar/meed/?short_id=KZCHN142135',
        'pages': [],
        'numPages': 0
      },
      {
        'link': 'https://s.pjm.gob.ar/meed/?short_id=YHCOJ191948',
        'pages': [],
        'numPages': 0
      },
      {
        'link': 'https://s.pjm.gob.ar/meed/?short_id=VEFMP1851',
        'pages': [],
        'numPages': 0
      },
      {
        'link': 'https://s.pjm.gob.ar/meed/?short_id=FEKGY11425',
        'pages': [],
        'numPages': 0
      },
      {
        'link': 'https://s.pjm.gob.ar/meed/?short_id=FGLJL12044',
        'pages': [],
        'numPages': 0
      }
    ]
    
  }

  nodeSelect(event) {
    console.log(event)
    if(event.node.label!=="Indice")
    {
      this.scroll( event.node.orden-1,0);
    }
  // 
}
  ngOnChanges(changes) 
  {


    if(isDevMode())  console.log("changes from pdf viewer")
    if(isDevMode())  console.log(changes)
    if(isDevMode()) console.log(this.master)

   this.num_exp=this.master['letra']+'-'+this.master['numero']+'/'+this.master['anio']

   if( this.checkExistingRender(this.num_exp))
   {
    if(isDevMode())  console.log("should not fetch all again")
    if(isDevMode())  console.log("lenght"+this.storageService.getRender().length)
     this.full_pics=[...this.storageService.getRender()]
     this.treeData=this.storageService.getTree()
     this.displayWorkingDialog=false
     this.showClassicView=true

   }else
   {
    if(isDevMode()) console.log("definitely needs to fetch all again")
     this.launchFirstStart()
     this.displayWorkingDialog=true
   }



  }  


  launchFirstStart()
  {

   this.documentService.getIDs(this.master['letra'],this.master['numero'],this.master['anio']).subscribe(arr=>
    {
      if(isDevMode()) console.log('))))))))))))))))))))))))))))))))))))))))))))))')
      if(isDevMode())   console.log(arr)
      arr.sort(function(a, b) {
        return a['orden'] - b['orden'];
      });
      
     
      for (let a = 0; a < arr.length; a++) {
        this.treeData[0]['children'].push( {
          "label": arr[a]['orden']+'-'+arr[a]['categoria']['categoria'],
          "orden":arr[a]['orden'],
          "data": "Home Folder",
          "expandedIcon": "pi pi-folder-open",
          "collapsedIcon": "pi pi-folder"
      })
        this.IDS_expediente.push(arr[a])
        
      }
      this.storageService.saveTree(this.treeData)

      this.downloadAllB64Docs(this.IDS_expediente).subscribe(r=>
        {
          if(isDevMode())  console.log("GOT EM ALL!!!!!");
          if(isDevMode())console.log(r);
          let t_arr=[]
          for (let a = 0; a < r.length; a++) {
            if(isDevMode()) console.log("is it bigger?");
            
            //console.log(r[a])

            if (r[a].length>0)//error handling in joinfork returns empty arr if failed
            {
              //console.log("inside 100");
              if(isDevMode()) console.log("TTTTTTTTTTTTTTTTTTTTTTT")
              if(isDevMode()) console.log(r[a])
             
              
              t_arr.push({'data':r[a][0]['archivo_b64'],'link': 'https://s.pjm.gob.ar/meed/?short_id=FGLJL12044','pages': [],'numPages': 0})
            }
            
          }



          this.docs=t_arr
          if(isDevMode())   console.log("GONNA SEND")
          if(isDevMode()) console.log(this.docs[0])
          this.renderOneDoc(this.docs[0],0)
          if(isDevMode()) console.log("==================================================")
          if(isDevMode())  console.log("==================================================")
          // console.log(this.get2Pages(this.docs[0],0).then(pages=>{
          // console.log("finally got 2 pages!")
          // console.log(pages)
          // let arr_prom=[]
          //   for(let a=0;a<pages.length;a++)
          //   {
          //     arr_prom.push(this.addImageToArray(pages[a],0,a))
          //     // this.addImageToArray(pages[a],0,a).then(r=>{
          //     //   console.log("GOT PAGE")
          //     //   console.log(r)
          //     //   })
             
          //   }
          //   Promise.all(arr_prom).then(couple=>
          //     {
          //       console.log("GOT COUPLE")
          //       console.log(couple)
          //       this.currentPagesPair=couple
          //     })

          // }))
          
        })


      
        if(isDevMode()) console.log(arr)
    })
  }

checkExistingRender(num_exp)
{
  if(isDevMode()) console.log("num_exp"+num_exp)
  if(isDevMode()) console.log("this.storageservice.getnumexp"+this.storageService.getNumExp())
 
  if (this.storageService.getNumExp()==num_exp)
  {
    //already saved
    return true
  }else 
  {
   return false
  }
}
  showBookDialog() {
    this.displayBookDialog = true;
}


  async ngOnInit(): Promise < void > {


//gonna check for already saved render
  



  

  this.firstStart=true
  this.global_activity=0


    this.global_pages_counter=0
    this.index_counter=0
    let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    //a4 width/height ratio is 1.41
    localStorage.setItem("vw", vw.toString())
    localStorage.setItem("vh", vh.toString())
    this.vw = vw.toString()
    this.vw_half = Math.floor(vw / 2).toString()
    this.vh = vh.toString()
    //PDFJS.disableWorker = true;
    
    localStorage.setItem("num_pages", "0")


    
  //this.displayWorkingDialog=true



  }



 renderAsync()
 {


    for (let index = 0; index < this.docs.length; index++) {
    
      let loadingTask = pdfjsLib.getDocument(this.docs[index].link)

      this.getPDFnumPages(loadingTask).then(r => {
        //if(isDevMode())console.log(r)
        this.numPages = r
        this.global_pages_counter+=r
        this.global_activity+=r


        for (let i = 0; i < this.numPages; i++) {
          
          this.getDocPage(loadingTask, i + 1).then(page => {
          //  this.localTimer()
            //if(isDevMode())console.log("got page 1 of first document")
            if(isDevMode())console.log(page)
            //if(isDevMode())console.log("gettin base64 of current page")
             this.addImageToArray(page,index,page['_pageIndex']).then(r=>
              {
   //if(isDevMode())console.log('holy fucking crap')
            //if(isDevMode())console.log(pageContent)
            let pageContent=r
            this.global_activity--;
            this.full_pics.push({'base64':pageContent['data'],'docIndex':pageContent['docIndex'],'pageIndex':pageContent['pageIndex']})
            //if(isDevMode())console.log("fullpicslenght"+this.full_pics.length);
            //if(isDevMode())console.log("global_pages_counter"+this.global_pages_counter);
            //if(isDevMode())console.log("docs.length"+this.docs.length);
            //if(isDevMode())console.log("index"+index)
           
            
            if(isDevMode())console.log(this.global_activity);
            
            if (this.global_activity<1 && this.full_pics.length==this.global_pages_counter )
            {
              alert("HF")
            }
            if(this.full_pics.length==this.global_pages_counter && this.docs.length==index+1 )
            {
              //every page render has been completed, now its time to load images in book
              if(isDevMode())console.log("ALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHED")
              if(isDevMode())console.log(this.full_pics)
              this.full_pics=_.orderBy(this.full_pics, ['docIndex', 'pageIndex'], ['asc', 'asc']);
             
              //this.full_pics.sort((a, b) => a['composedIndex'].localeCompare(b['composedIndex']));
              if(isDevMode())console.log("sorted final");
              if(isDevMode())console.log(this.full_pics)
              //this.loadBook()
            }
              })
         
          })

        }

      })

    }
 }

  downloadAllB64Docs(ids): Observable<any[]>
  { 

let prom_arr=[]

    for (let a = 0; a < ids.length; a++) {



      prom_arr.push(this.documentService.getDocumentB64(ids[a]['id']).pipe(map((res) => res), catchError(e => of([]))))
    //     {
        
    //     if(isDevMode())console.log("gotIt");
    //     this.DownloadedB64Docs.push(r)
    //     if(isDevMode())console.log(r)
     
    //  },
    //  err => {
    //    if(isDevMode())console.log("error 500 server")
    //    if(isDevMode())console.log(err);
    //   // check error status code is 500, if so, do some action
    //  }
      
    }

    return forkJoin(prom_arr)

  }

    



   convertDataURIToBinary(dataURI) {
    let BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
  
    for(let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
    
  

  allReady()
 {
   this.displayWorkingDialog=false
   if(isDevMode())console.log("ALL READY")
  
   this.full_pics=_.orderBy(this.full_pics, ['docIndex', 'pageIndex'], ['asc', 'asc']);
   this.storageService.saveRender(this.full_pics,this.num_exp)
   if(isDevMode())console.log(this.full_pics);
   this.showClassicView=true
   
 }






get2Pages(doc,page)
{
  let fpages=[]
  let loadingTask = pdfjsLib.getDocument({'data':atob(doc.data)})
if(page==0){page++}
  let page1=this.getDocPage(loadingTask, page)
  let page2=this.getDocPage(loadingTask, page+1)
  return Promise.all([page1,page2])
}

 renderOneDoc(doc,docIndex)
{
   if(isDevMode())console.log("___________________________________________________________________________________________________________")
   if(isDevMode())console.log(doc.data)

     let loadingTask = pdfjsLib.getDocument({'data':atob(doc.data)})

     this.getPDFnumPages(loadingTask).then(r => {
       //if(isDevMode())console.log(r)
       this.numPages = r
       this.global_pages_counter+=r
       this.global_activity+=r


       for (let i = 0; i < this.numPages; i++) {
         
         this.getDocPage(loadingTask, i + 1).then(page => {
         //  this.localTimer()
           //if(isDevMode())console.log("got page 1 of first document")
          // if(isDevMode())console.log(page)
           //if(isDevMode())console.log("gettin base64 of current page")
            this.addImageToArray(page,docIndex,page['_pageIndex']).then(r=>
             {
  //if(isDevMode())console.log('holy fucking crap')
           //if(isDevMode())console.log(pageContent)
           let pageContent=r
           this.global_activity--;
           this.full_pics.push({'base64':pageContent['data'],'docIndex':pageContent['docIndex'],'pageIndex':pageContent['pageIndex']})
           //if(isDevMode())console.log("fullpicslenght"+this.full_pics.length);
           //if(isDevMode())console.log("global_pages_counter"+this.global_pages_counter);
           //if(isDevMode())console.log("docs.length"+this.docs.length);
           //if(isDevMode())console.log("index"+index)
          
           
        //   if(isDevMode())console.log(this.global_activity);
          // if(isDevMode())console.log(("docs lenght////" +this.docs.length));
          // if(isDevMode())console.log("docindex///"+docIndex);
           
           if (this.global_activity<1  )
           {
            
             
             
            if(this.docs.length>docIndex+1)
            {
              this.renderOneDoc(this.docs[docIndex+1],docIndex+1)
            }else
            {

              this.allReady();
            }
             //return greeting = await Promise.resolve("Hello");
            // alert("HF")
           }


          //  if(this.full_pics.length==this.global_pages_counter)
          //  {
          //    //every page render has been completed, now its time to load images in book
          //    if(isDevMode())console.log("ALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHED")
          //    if(isDevMode())console.log(this.full_pics)
          //    this.full_pics=_.orderBy(this.full_pics, ['docIndex', 'pageIndex'], ['asc', 'asc']);
          //    //this.full_pics.sort((a, b) => a['composedIndex'].localeCompare(b['composedIndex']));
          //    if(isDevMode())console.log("sorted final");
          //    if(isDevMode())console.log(this.full_pics)
          //    //this.loadBook()
          //  }
             })

        
         })

       }

     })

   


}




  getPDFnumPages(loadingT) {
    return loadingT.promise.then(function (pdf) {
      return pdf.numPages;
    })
  }

loadBook()
{

   //every page render has been completed, now its time to load images in book
   if(isDevMode())console.log("ALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHEDALL FINISHED")
   if(isDevMode())console.log(this.full_pics)
   this.full_pics=_.orderBy(this.full_pics, ['docIndex', 'pageIndex'], ['asc', 'asc']);
   //this.full_pics.sort((a, b) => a['composedIndex'].localeCompare(b['composedIndex']));
   if(isDevMode())console.log("sorted final");
   if(isDevMode())console.log(this.full_pics)

  
   const target= document.getElementById("flipbook");
   const newFlip = document.createElement('div');
   $( '#flipbook' ).replaceWith(newFlip)

 //  https://stackoverflow.com/questions/843680/how-to-replace-dom-element-in-place-using-javascript/40444300


  $("#flipbook").turn({
    width: parseInt(localStorage.getItem('vw')),
    height: parseInt(localStorage.getItem('vh')),
    autoCenter: true,
    display:"double", //double or single
    aceleration:true,
    inclination:50,
    when: {turned: function(event, page, pageObj) {
      //alert("the current page is "+ page);
    }
  }

  });
  
}

setViewStyle(style)
{
 

if (style=="magazine")
{
  this.showMagazineView=true;
  this.showClassicView=false;
 // $("#flipbook").turn("destroy");

}else
{




  this.showMagazineView=false;
  this.showClassicView=true;
}
}

prevPage()
{
  $("#flipbook").turn("previous");
}
nextPage()
{
  $("#flipbook").turn("next");
}


  addImageToArray(page, docIndex,pageIndex) {


    //console.log(page);
    var scale = 1.5;
    var viewport = page.getViewport({
      scale: scale,
    });
    //console.log("before getting element id")
    var canvas = < HTMLCanvasElement > document.createElement('canvas');
    canvas.setAttribute("id", docIndex.toString()+pageIndex.toString());
    var context = canvas.getContext('2d');
    context.canvas.hidden = true;
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    var renderContext = {
      canvasContext: context,
      viewport: viewport
    };
    ////console.log("after rendering context")
    //page.render(renderContext);
    var renderTask = page.render(renderContext);
    //console.log("right after renderTask creation")



    return renderTask.promise.then(function () {
      //get numpages, then maybe render everything and save it in a blob array?? and when thats finished, ngfor on image tags
      //console.log("CANVAS RENDERED inside promise")
      // context.drawImage(canvas, 0, 0);
      var dataURL = canvas.toDataURL("image/png");
      ////console.log(dataURL)

      if (context) {
        //console.log(context);
        //console.log("got context")
        //context.clearRect(0, 0, canvas.width, canvas.height);
        //context.beginPath();

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, canvas.width, canvas.height);
        // Restore the transform
        context.restore(); //CLEARS THE SPECIFIC CANVAS COMPLETELY FOR NEW DRAWING
      }
      renderTask.cancel();

      //console.log("dataURL")
      //console.log(dataURL)
      return {"data":dataURL,"docIndex":docIndex,"pageIndex":pageIndex}


    })

  }


  getDocPage(loadingT, pageNumber) {

    return loadingT.promise.then(function (pdf) {
      // you can now use *pdf* here
      var numPages = pdf.numPages;
      //console.log("PAGES")
      //console.log(numPages)
      localStorage.setItem("numPages", numPages)


      console.log("before getting page")
      return pdf.getPage(pageNumber)






    })
  }

  scroll(docIndex,pageIndex) {
    let cannot_start_with_digit='m'
    let t='m'+docIndex+'-'+pageIndex
    document.querySelector('#'+t).scrollIntoView({ behavior: 'smooth', block: 'center' });
 }




}