export class filtersPersona {
    documento;
    rol;
    apellidos;
    nombres;
    prontuario;
    complejo;
    situacionPers;
    situacionProc;


}