export class Expediente
{    
        id;
        unidad_fiscal;
        fiscalia;
        fiscal;
        asesoria;
        fecha_creacion;
        fecha_modificacion;
        fecha_etapa;
        letra;
        numero_expediente;
        anio;
        numero;
        numero_anterior;
        caratula;
        etapa;
        organismo;
        proceso;
        tipo_expediente;
        letra_ppal;
        numero_expediente_ppal;
        anio_ppal;
        principal;
        querellante;
        actor_civil;
        fecha_de_ingreso;
        situacion;
        slug;
        admitido;
        motivo;
        transferencia_campo_abierto;
        fecha_de_transferencia;
        usuario_carga;
        usuario_modificacion;      
}