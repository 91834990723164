import { Component, OnInit,isDevMode } from '@angular/core';
import { TablaFiltroComponent } from '../tabla-filtro/tabla-filtro.component';
import { DetalleExpedienteService } from '../services/detalle-expediente.service';
import { filtersPersona } from '../interfaces/filtersPersona';
import { stringify } from '@angular/compiler/src/util';
import { FiltersLegajoItem } from '../interfaces/filtersLegajo';




@Component({
  selector: 'app-consulta-persona',
  templateUrl: './consulta-persona.component.html',
  styleUrls: ['./consulta-persona.component.scss']
})
export class ConsultaPersonaComponent implements OnInit {

  documento: string;
  apellido: string;
  apellidos:string;
  nombre: string;
  nombres: string;
  prontuario: string;
  cities: any[];
  rol: any[];
  complejo:any[];
  situacionPers: any[];
  situacionProc: any[];
  selectedRol: any;
  selectedCity: any;

  filterParams:filtersPersona;
  //filterParams:FiltersLegajoItem;
  //origin='legajo';
  origin='persona';


  isLoading_informacionGeneral=true;
  roles=[];

  //testInput='P/64793/20'

  constructor(private detalleExpedienteService:DetalleExpedienteService){
        /* this.cities = [
            {name: 'New York', code: 'NY'},
            {name: 'Rome', code: 'RM'},
            {name: 'London', code: 'LDN'},
            {name: 'Istanbul', code: 'IST'},
            {name: 'Paris', code: 'PRS'}
        ]; */
    }

  ngOnInit(): void {
  
   /*  this.getRoles();
    this.getRepo();   */

  
  }

/*   getRoles(){
    if(isDevMode()) if(isDevMode()) console.log("fetching some data from led api")
    //Load data with fixed expediente ID for developing purposes
    this.detalleExpedienteService.getRol(this.testInput).subscribe(r=>{
    this.isLoading_informacionGeneral=true;
    if(isDevMode()) if(isDevMode()) console.log(r)
    if(r[0]!=undefined)
      {
        for(let i = 0; i < r.length; i++)
        this.roles[i]=r[i].rol
        if(isDevMode()) if(isDevMode()) console.log(this.roles) 

      }
    })
    
  }

  getRepo(){
    this.detalleExpedienteService.getRepositorio(this.testInput).subscribe(r=>{
      if(isDevMode()) if(isDevMode()) console.log("pruebas")
      if(isDevMode()) if(isDevMode()) console.log(r)
    })
  } */

  expedienteLookup()
   {
     if(isDevMode()) console.log("looking up expediente por persona")
     
     let tempFilterParams= new filtersPersona();
     //let tempFilterParams= new FiltersLegajoItem();
     if(isDevMode()) console.log(tempFilterParams);
      
     if (this.documento!==undefined){tempFilterParams.documento=this.documento}
     //if (this.documento!==undefined){tempFilterParams.numero=this.documento}

     //se anulan todos los demás filtros para dejar inicialmente
     /* if (this.rol!==undefined){tempFilterParams.rol=this.rol}
     if (this.apellidos!==undefined){tempFilterParams.apellidos=this.apellidos}
     if (this.nombres!==undefined){tempFilterParams.nombres=this.nombres}
     if (this.prontuario!==undefined){tempFilterParams.prontuario=this.prontuario}
     if (this.complejo!==undefined){tempFilterParams.complejo=this.complejo}
     if (this.situacionPers!==undefined){tempFilterParams.situacionPers=this.situacionPers}
     if (this.situacionProc!==undefined){tempFilterParams.situacionProc=this.situacionProc} */


     
     if(isDevMode()) console.log("tempFilterParams=")
     if(isDevMode()) console.log(tempFilterParams);
     //trigger change
     this.filterParams=tempFilterParams
   }


   clearFilters()
   {
     this.documento=undefined;
     /* this.prontuario=undefined;
     this.apellidos=undefined;
     this.rol=undefined;
     this.complejo=undefined;
     this.nombres=undefined;
     this.situacionPers=undefined;
     this.situacionProc=undefined; */

   }

}
