export class FiltersLegajoItem {
    numero?;
    letra?;
    anio?;
    caratula?;
    organismo?;
    proceso?;
    etapa?;
    creacion?;
    numeroContiene?;
    fechaCreacion?;


}





