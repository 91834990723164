export class Ejecucion {
    id;
    persona;
    sentencia;
    concepto;
    dia;
    mes;
    anio;
    campo_abierto;
    fecha_creacion;
    usuario_carga;
    fecha_modificacion;
    usuario_modificacion;
    numero;



}