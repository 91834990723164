import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import {FiltersLegajoItem} from '../interfaces/filtersLegajo'
import { Output, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TablaService {
  
  showDetail: any;
  @Output() change: EventEmitter<boolean> = new EventEmitter();
 
   toggle() {
    this.showDetail = true;
    this.change.emit(this.showDetail);
  } 

  constructor(private http:HttpClient) { }



  getExpedientes(origin,filters:any): Observable<any[]>
  {
    //1/Expedientes/filters/<numero>/<caratula>/<organismo>/<proceso>/<numero_anterior>/<etapa>
    //expecting to receive something like this 'P/64793/20'
    let server =  environment.led_api;
    //server="https://dev-led.pjm.gob.ar/apirest/v1"
    const segment_tail= '?format=json';   
    let filtersFinalString=''
    let segment_head='';
    if(origin=="legajo")
    {
      segment_head='/legajo/';
      
      console.log(filters)
      if (filters.letra!==undefined){filtersFinalString+=filters.letra+'/'}else{filtersFinalString+='None/'}
      if (filters.numero!==undefined){filtersFinalString+=filters.numero+'/'}else{filtersFinalString+='None/'}
      if (filters.anio!==undefined){filtersFinalString+=filters.anio+'/'}else{filtersFinalString+='None/'}
      // if (filters.proceso!==undefined){filtersFinalString+=filters.proceso.name+'/'}else{filtersFinalString+='None/'}
      // if (filters.numeroContiene!==undefined){filtersFinalString+=filters.numeroContiene+'/'}else{filtersFinalString+='None/'}
      // if (filters.etapa!==undefined){filtersFinalString+=filters.etapa.name+'/'}else{filtersFinalString+='None/'}
    
      filtersFinalString=filtersFinalString.substr(0,filtersFinalString.length-1)
    }
    else if(origin=="persona"){
      segment_head='/persona/';
      ///Personas/filters/{numero_documento}/{apellidos}/{nombres}/{rol}/{situacion_personal}/{situacion_procesal}/{complejo_de_detencion}
      console.log(filters)
     if (filters.documento!==undefined){filtersFinalString+=filters.documento+'/'}else{filtersFinalString+='None/'}
     /* if (filters.apellidos!==undefined){filtersFinalString+=filters.apellidos+'/'}else{filtersFinalString+='None/'}
     if (filters.nombres!==undefined){filtersFinalString+=filters.nombres+'/'}else{filtersFinalString+='None/'}
     if (filters.rol!==undefined){filtersFinalString+=filters.rol+'/'}else{filtersFinalString+='None/'}
     //if (filters.prontuario!==undefined){filtersFinalString+=filters.prontuario+'/'}else{filtersFinalString+='None/'}     
     if (filters.situacionPers!==undefined){filtersFinalString+=filters.situacionPers+'/'}else{filtersFinalString+='None/'}
     if (filters.situacionProc!==undefined){filtersFinalString+=filters.situacionProc+'/'}else{filtersFinalString+='None/'}
     if (filters.complejo!==undefined){filtersFinalString+=filters.complejo+'/'}else{filtersFinalString+='None/'} */
   
    
     filtersFinalString=filtersFinalString.substr(0,filtersFinalString.length-1)
    }
   
   return  this.http.get<any[]>(server+segment_head+filtersFinalString+segment_tail)

  }


  setStateShowDetail(show){
    this.showDetail = show;
  }
   
  getStateShowDetail():boolean{
    return this.showDetail;
  }
  

}




