import { Injectable } from '@angular/core';
import {Observable,Observer} from 'rxjs'
import { Subject,BehaviorSubject } from 'rxjs';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private keycloakService:KeycloakService) { }
   sessionNotifier = new BehaviorSubject<any>(false);
  
logout()
{
  this.keycloakService.logout()
}
getStateSessionData()
{
  return this.sessionNotifier
 
}
getSessionData()
{
  return this.keycloakService.getToken()
 
}
hasSessionActive(state)
{
 if (state==true)
 {
  this.sessionNotifier.next(true)
 }
}

}
