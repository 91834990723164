
<div class="row">
<div class="col-3"> 
  <!-- <a class="nav-link  hidden-sm-down text-muted  " style="cursor: pointer;" [routerLink]="['/dash/legajo']">Legajo</a>  -->
   <button class="btn btn-secondary" [routerLink]="['/dash/legajo']" (click)="buttonSelected('legajo')" style="align-self: right;">Legajo</button>

</div>

    <div class="col-3">
        <button class="btn btn-secondary" [routerLink]="['/dash/persona']" (click)="buttonSelected('persona')" style="align-self: left;">Persona</button>
        <!-- <a class="nav-link  hidden-sm-down text-muted  " style="cursor: pointer;" [routerLink]="['/dash/persona']" >Persona</a> -->

    </div>
    
</div>

