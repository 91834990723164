export class DatosCondena{
    id;
    persona;
    tipo_condena;
    fecha_de_cumplimiento;
    finaliza_fecha_cumplimiento?;
    fecha_libertad_condicional;
    prision_perpetua;
    reincidencia;
    ley;
    art27_4;
    art27_8;
    art27_10;
    periodo;
    fase;
    fecha_creacion;
    usuario_carga;
    fecha_modificacion;
    usuario_modificacion;
    numero;

  }