import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { now } from 'moment';
import { SecurityService } from './services/security.service';

@Injectable()
export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(protected router: Router,
     protected keycloakAngular: KeycloakService,
     private securityService:SecurityService) {
    super(router, keycloakAngular);
  }

requiredRoles=['ABOGADO-PUEDE-CONSULTAR']

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
          console.log("not autenticared. login in");
          
        this.keycloakAngular.login();
        return;
      }
      console.log("FROM AUTHGUARd")
      console.log(this.roles)
     // const requiredRoles = route.data.roles;
      // if (!requiredRoles || requiredRoles.length === 0) {
      //   return resolve(true);
      // } else {
      //   if (!this.roles || this.roles.length === 0) {
      //    // resolve(false);
      //    resolve(true);
      //   }
        let granted: boolean = false;
        // for (const requiredRole of requiredRoles) {
        //   if (this.roles.indexOf(requiredRole) > -1) {
        //     granted = true;
        //     break;
        //   }
        // }
        //resolve(granted);
      let result=false
      
                    for(let a=0;a<this.requiredRoles.length;a++)
                    {
                      for(let b=0;b<this.roles.length;b++)
                      {
                        if(this.requiredRoles[a]==this.roles[b])
                        {
                        //this script will return OK to the first match, meaning it does not supports multiple roles
                        //for abogados for now. to review
                        console.log("correct role found.")
                        result=true
                        }
                      }
                    
                    }

                    if(result){resolve(true)}
                    else{
                      setTimeout(() => this.keycloakAngular.logout(), 1000);
                  
                     
                    console.log("resolved false logout")
                        resolve(false)
                        this.router.navigate(['/not-allowed']);
                     
                    
                   
                    }
        });
       
      };
    }
  
