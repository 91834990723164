import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable, of } from 'rxjs';
import {TablaItem} from '../interfaces/tablaItem'
@Injectable({
  providedIn: 'root'
})
export class CasosService {

  constructor(private http:HttpClient) { }

  getCasos(): Observable<TablaItem[]>
  {

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'skip-interceptor': 'true'
    //   })
    // };
    
   
    
    const server =  environment.casos;

   return  this.http.get<TablaItem[]>(server)
   //ged-backend-cloud-master/novedades
  }
}
