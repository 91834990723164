import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from './../../environments/environment';
import {SecurityService} from './../services/security.service';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})





export class NotAllowedComponent implements OnInit {

  constructor(private securityService:SecurityService,private router:Router) {  }
logout_url=''
  ngOnInit(): void {
   
    //this.logout()
  }


  logout()
  {
    this.router.navigate(['/']);
  // this.logout_url=environment.KC_URL + '/auth/realms/' + environment.KC_REALM + '/protocol/openid-connect/logout?redirect_uri=' + environment.LOGIN_HOME_ENCODED
   //console.log(logout_url);
  // localStorage.clear();

  

    // window.open(this.logout_url);
   
 
   // alert()
    //window.location.replace(logout_url);
  }




}
