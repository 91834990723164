import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import {FiltersLegajoItem} from '../interfaces/filtersLegajo'

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http:HttpClient) { }



  getIDs(letra,expediente,anio): Observable<any[]>
  {
  let server=environment.led_api+'/repositorio/'+letra+"/"+expediente+"/"+anio
   return  this.http.get<any[]>(server)

  }

  getDocumentB64(idDoc): Observable<any[]>
  {
    let server=environment.led_api+'/actuacion/b64/'+idDoc+'/'
    return  this.http.get<any[]>(server)  }



getDocument(idDoc): Observable<any>
{


  let server=environment.led_api+'/actuacion/download/'+idDoc+'/'
  return  this.http.get<any>(server,  {
    responseType: 'arraybuffer' as 'json'},
  ) 


}

}




