import { ChangeDetectorRef, Component, OnInit ,isDevMode} from '@angular/core';
import { faCalendarCheck, faUserShield, faCommentDots,faExclamationTriangle, faMicroscope, faStreetView, faUsers, faBriefcase, faInfo, faBalanceScale, faMale, faFolderOpen, faFolder, faEye, faAddressBook, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import {DetalleExpedienteService} from './../services/detalle-expediente.service';
import {TablaService} from './../services/tabla.service';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap,Params } from '@angular/router';
//import { DateTime, Settings, Info } from 'luxon';
//import { ConsoleReporter } from 'jasmine';

//--- models ----
import { Expediente } from '../interfaces/expediente';
import { Tarea } from '../interfaces/tarea';
import { DefensorTecnico } from '../interfaces/defensorTecnico';
import { Audiencia } from '../interfaces/audiencia';
import { Persona } from '../interfaces/persona';
import {Delito} from './../interfaces/delito';
import {HabeasCorpus} from './../interfaces/habeasCorpus';
import {Ejecucion} from './../interfaces/ejecucion';
import {FiltersLegajoItem} from './../interfaces/filtersLegajo';
import { filtersPersona} from './../interfaces/filtersPersona';
import { Rol } from '../interfaces/rol';
import { TablaFiltroComponent } from '../tabla-filtro/tabla-filtro.component';
import { r3JitTypeSourceSpan } from '@angular/compiler';
import { DatosCondena } from '../interfaces/datosCondena';
import { getMultipleValuesInSingleSelectionError } from '@angular/cdk/collections';
import { Magistrado } from '../interfaces/magistrado';
import { browser, element } from 'protractor';
import { Sentencia } from '../interfaces/sentencia';
import {PdfviewerComponent} from '../pdfviewer/pdfviewer.component';
import {ActuacionesDigitalizadasComponent} from '../actuaciones-digitalizadas/actuaciones-digitalizadas.component';
import { filter } from 'rxjs/operators';
import {HostListener,Directive,HostBinding} from '@angular/core';
import { DocumentService } from '../services/document.service';
import { ActuacionDigitalizada } from '../interfaces/actuacionDigitalizada';
import { LazyLoadEvent } from 'primeng/api';

//import { ConsoleReporter } from 'jasmine';

@Component({
selector: 'app-detalle-expediente',
templateUrl: './detalle-expediente.component.html',
styleUrls: ['./detalle-expediente.component.scss'],
})
export class DetalleExpedienteComponent implements OnInit {

@HostListener('ngOnInit')

//----------
public localDatetime;
//---------

//----------- íconos -------
faCalendarCheck = faCalendarCheck;
faUserShield = faUserShield;
faCommentDots = faCommentDots;
faMicroscope = faMicroscope;
faStreetView = faStreetView;
faUsers = faUsers;
faBriefcase = faBriefcase;
faInfo = faInfo;
faBalanceScale = faBalanceScale;
faMale = faMale;
faFolderOpen = faFolderOpen;
faFolder = faFolder;
faEye = faEye;
faAddressBook = faAddressBook;
faExcTriangle = faExclamationTriangle;
faFilePdf= faFilePdf;

//----------- 

informacionGeneral=[];
isLoading_informacionGeneral=true;
magistrados:Magistrado[]=[];
magistradosRecurso:Magistrado[]=[];
defensorTec=[];
hechos=[];
//defensores:Array<DefensorTecnico>;
defensores:Array<any>=[];
delitos = [];
expediente: Expediente;
tareas:Array<Tarea>;
tareaActual= {} as Tarea;
peritos:Array<any>=[] ;
habeasCorpuses:HabeasCorpus[]=[];
ejecuciones:Ejecucion[]=[];
datosCondena={} as DatosCondena;
datosCondenas:DatosCondena[]=[];
victimas=[];
imputados=[];
persona:Persona;
antecedentes:Array<any>;
roles=[];
audiencias:Array<Audiencia>=[];
audiencias_pasadas:Array<any>=[];
audiencias_futuras:Array<any>=[];
proximasAudiencias:Array<any>=[];
//expediente;
track=[];
responsable='';
fecha_creacion;
accion='';
fecha_hora;
carpetaOP1=true;
carpetaOP2=false;
carpetaOP3=false;
expediente_input='';
anio_input='';
letra_input='';
origin=''
f_ingreso;
creado;
fechaActual;
fechaAudiencia;
fecha_etapa;
letra_expediente;
vinculadosHC=[];
sentencias: Sentencia[]=[];
src = '/assets/pdf-test.pdf'
testInput='P/64793/20' 
filters:FiltersLegajoItem={}
//dependientes:Array<Expediente>=[];
dependientes:Expediente[]=[];
pdfsSentencia:ActuacionDigitalizada[]=[];
selected: Blob;
pdfDetail:ActuacionDigitalizada=null;

//------pagination
pagePasadas: number = 1;
pageFuturas: number = 1;
showPagination: boolean = false;
expteAudiencias: string;
firstIndex = 10; 
rowsPasadas = 50;
rowsFuturas = 30;
rows = 5;
loading: boolean =true;
totalRecordsFuturas: number=0;
totalRecordsPasadas: number=0;
totalRecords:number=0;
audProximasCargadas: boolean = false;
fechaActualAud;
isLastPagePasadas:boolean = false;
isLastPageFuturas:boolean = false;
ultimaPag:boolean=false;


// totalItems: number;
page: number = 1;
// previousPage: number=1;
// showPagination: boolean = false;
// expteAudiencias: string;
// firstIndex = 10; 
// rowsPasadas = 20;
// rowsFuturas = 10;
// rows = 5;
// countAud = 0;
// loading: boolean =true;
// totalRecordsFuturas: number=0;
// totalRecordsPasadas: number=0;
// totalRecords:number=0;
// audProximasCargadas: boolean = false;
// audiencias: Array<any>=[];
// cantPaginasPasadas:number=1;
// //offset:number = 100;




//------bool values to hide or show cards while loading
showDatosDeCondenaCard=false
showCondenadoCard=false
alertaImputados: boolean = false;
buttonPersona: boolean = false;
showViewer=false;
showxrays=false;
showActualizacionesDigitalizadas=false;
recurso: any;
isLoading_Principal: boolean = true;
creadoRecurso: {};
recursoTiempoEtapa: {};
isLoading_antecedentes: boolean;
conDependientes: boolean = false;
showPDF: boolean;
 magistradosPpal:Magistrado[]=[];    
    

constructor( 
private detalleExpedienteService:DetalleExpedienteService,
private route: ActivatedRoute,
private router: Router,
private tablaService:TablaService,
private documentService: DocumentService,
private cd: ChangeDetectorRef) { 
//Settings.defaultLocale = 'es';
}

ngOnInit(): void {

    this.tablaService.toggle()

    this.showxrays=true;

    this.route.params.subscribe((params: Params) => {

    this.expediente_input = params['numero_expediente']
    this.anio_input = params['anio']
    this.letra_input = params['letra']
    //this.origin=params['origin']
    this.origin='legajo'
    if(isDevMode()) console.log("----------------------------------------------------");
    if(isDevMode()) console.log(this.letra_input+'/'+this.expediente_input+this.anio_input+'-')

    this.filters.anio=this.anio_input;
    this.filters.numero=this.expediente_input;
    this.filters.letra=this.letra_input;
    this.tablaService.getExpedientes(this.origin,this.filters).subscribe(r=>
    {
        this.letra_expediente = r['Expediente'][0]['letra']
        if(isDevMode()) console.log(this.letra_expediente)

        if(isDevMode()) console.log("expediente fetched again from EP")
        if(isDevMode()) console.log(r)
        
        if(this.letra_expediente == 'R'){
            this.load_recurso(r)
        }else{ 
            this.load_expediente(r); 
            this.load_delitos(r)
            this.load_tablasParticipantes(r);
            this.load_defensaTecnica(r);
            this.load_HabeasCorpus(r);
            this.load_ejecucion(r);
            this.load_magistrado(r);
            this.load_hechos(r);
            this.load_alertaImputados(r);
        } 

        this.load_tareas(r);

        //this.load_audiencias(r);
        //----------Paginación audiencias
        //this.page =1;
        this.expteAudiencias = this.formatoExpAudiencias(r);
        // this.expteAudiencias ='P000006003017';        
        this.fechaActualAud = moment().format('DD-MM-YYYY');        
        this.load_audiencias_futuras(this.expteAudiencias, this.pagePasadas, this.fechaActualAud, this.rowsFuturas);
        this.load_audiencias_pasadas(this.expteAudiencias, this.pageFuturas, this.fechaActualAud, this.rowsPasadas);

        this.load_dependientes(this.filters);
    },
    error =>{ console.log("Ha ocurrido un error en el back ......"+error)
    })
    });

    if(isDevMode()) console.log("----------------------------------------------------");
    this.carpetaOP1=true;
    moment.locale('es');

}

ngAfterViewChecked(): void{
    this.cd.detectChanges();
}

load_dependientes(filters){   

    if (filters != undefined && filters != ""){
        //Busca si tiene expedientes vinculados
        this.detalleExpedienteService.getVinculados(filters).subscribe(r=>
            {
                if( r[0]!= undefined){ 
                    
                    this.conDependientes = true; 
                    let result = r
                    
                    if(isDevMode()) console.log(r)
                    if(isDevMode()) console.log("expedientes dependientes ...... " + r[0])
                    if(isDevMode()) console.log(result.length)

                    for (let index = 0; index < result.length; index++) {

                        console.log(result[index])
                       
                        let dep_temp= new Expediente();

                        if(isDevMode()) console.log(result[index]['numero_expediente']+' '+filters.numero+' ' + result[index]['letra']+' '+filters.letra +' '+ result[index]['anio']+' '+filters.anio )

                        if(result[index]['numero_expediente']!=filters.numero || result[index]['letra']!=filters.letra || result[index]['anio']!=filters.anio){
                            if(isDevMode()) console.log(result[index]['letra'])
                            
                            if(result[index]['numero_expediente']==filters.numero && result[index]['letra']!=filters.letra && result[index]['anio']==filters.anio && result[index]['letra']=='R'){

                                if(isDevMode()) console.log("recurso letra R")
                                if(isDevMode()) console.log(this.letra_expediente)

                                if(this.letra_expediente=='R'){
                                    if(result.length<1){
                                    this.conDependientes = false;
                                    this.isLoading_informacionGeneral = false;}
                                }else{
                                    this.conDependientes=true;
                                    dep_temp.numero=result[index]['numero']
                                    console.log(result[index])
                                    dep_temp.letra=result[index]['letra']
                                    dep_temp.anio=result[index]['anio']
                                    dep_temp.tipo_expediente=result[index]['tipo_expediente']
                                    dep_temp.numero_expediente=result[index]['numero_expediente']
                                    dep_temp.motivo=result[index]['motivo']
                                    dep_temp.etapa=result[index]['etapa']
                                    dep_temp.transferencia_campo_abierto=result[index]['transferencia_campo_abierto']
                                    
                                    if(isDevMode()) console.log(dep_temp.tipo_expediente)
                                    console.log(this.conDependientes)
                                    //dep_temp.solicitante=array_HC[index]['solicitante']
                                    
                                    this.dependientes.push(dep_temp) 
                                    }            

                                this.isLoading_informacionGeneral=false; 
                            
                            }else{ 
                                //this.conDependientes = false;
                                this.conDependientes=true;
                                    dep_temp.numero=result[index]['numero']
                                    console.log(result[index])
                                    dep_temp.letra=result[index]['letra']
                                    dep_temp.anio=result[index]['anio']
                                    dep_temp.tipo_expediente=result[index]['tipo_expediente']
                                    dep_temp.numero_expediente=result[index]['numero_expediente']
                                    dep_temp.motivo=result[index]['motivo']
                                    dep_temp.etapa=result[index]['etapa']
                                    dep_temp.transferencia_campo_abierto=result[index]['transferencia_campo_abierto']
                                    
                                    if(isDevMode()) console.log(dep_temp.tipo_expediente)
                                    console.log(this.conDependientes)
                                    //dep_temp.solicitante=array_HC[index]['solicitante']
                                    
                                    this.dependientes.push(dep_temp) 

                                this.isLoading_informacionGeneral=false;} 
                        }
                    }
                }else{ 
                    if(isDevMode()) console.log("No tiene dependientes")

                    this.conDependientes = false;
                    this.isLoading_informacionGeneral=false;} 
                
        },
        error => {
            console.log("Ha ocurrido un error en el back ......"+error)
            this.isLoading_informacionGeneral=false;

        })
    }
}

load_HabeasCorpus(obj)
{
//Habeas Corpus
if ('DatosHC' in obj)

{

let array_HC=obj['DatosHC']
if(isDevMode()) console.log("block habeas corpuses")
for (let index = 0; index < array_HC.length; index++) {
let habeas_temp= new HabeasCorpus();

habeas_temp.id=array_HC[index]['id']
habeas_temp.categoria=array_HC[index]['categoria']
habeas_temp.beneficiario=array_HC[index]['beneficiario']
habeas_temp.beneficiario_descripcion=array_HC[index]['beneficiario_descripcion']
habeas_temp.solicitante=array_HC[index]['solicitante']
habeas_temp.fecha_creacion=array_HC[index]['fecha_creacion']
habeas_temp.usuario_carga=array_HC[index]['usuario_carga']
habeas_temp.fecha_modificacion=array_HC[index]['fecha_modificacion']
habeas_temp.usuario_modificacion=moment(array_HC[index]['usuario_modificacion']).format('LLL')
habeas_temp.numero=array_HC[index]['numero']
this.habeasCorpuses.push(habeas_temp)
if(isDevMode()) console.log(this.habeasCorpuses[0])
}

if ('LegajoAsociado' in obj){
let arrayVinculados = obj['LegajoAsociado']

arrayVinculados.forEach(vinculado => {
let temp_legajo = {}
let legajo = vinculado['legajo_asociado']
temp_legajo['legajo_asociado']=legajo

if(legajo.search('-')>0){
temp_legajo['letra']=legajo.slice(0,legajo.search('-'))
if(isDevMode()) console.log(temp_legajo['letra'])}
if(legajo.search('/')>0){
var numero = legajo.slice((legajo.indexOf('-')+1),legajo.indexOf('/')) 
temp_legajo['anio']= legajo.slice(-2)
}else{var numero = legajo.slice((legajo.indexOf('-')+1))}

numero = numero.replace(/\D/g,'');
temp_legajo['numero']=numero
if(isDevMode()) console.log(temp_legajo['numero'])
if(isDevMode()) console.log('aniooooooooooo-?????????'+temp_legajo['anio'])
this.vinculadosHC.push(temp_legajo) 

}, 
error =>{}
);

} 

}


}
load_ejecucion(obj)
{

if ('Rol' in obj)
{
let array_Rol=obj['Rol']
if(isDevMode()) console.log("block rol")
for (let index = 0; index < array_Rol.length; index++) {
let rol_temp= new Rol();
rol_temp.id=array_Rol[index]['id']
rol_temp.persona=array_Rol[index]['persona']
rol_temp.rol=array_Rol[index]['rol']
rol_temp.id_numero_id_persona=array_Rol[index]['id_numero_id_persona']
rol_temp.campo_abierto=array_Rol[index]['campo_abierto']
rol_temp.situacion_personal=array_Rol[index]['situacion_personal']
rol_temp.situacion_procesal=array_Rol[index]['situacion_procesal']
rol_temp.complejo_de_detencion=array_Rol[index]['complejo_de_detencion']
rol_temp.prontuario=array_Rol[index]['prontuario']
rol_temp.fecha_de_aprehension=array_Rol[index]['fecha_de_aprehension']
rol_temp.situacion=array_Rol[index]['situacion']
rol_temp.visibilidad=array_Rol[index]['visibilidad']
rol_temp.fecha_creacion=array_Rol[index]['fecha_creacion']
rol_temp.usuario_carga=array_Rol[index]['usuario_carga']
rol_temp.fecha_modificacion=moment(array_Rol[index]['fecha_modificacion']).format('LLL')
rol_temp.usuario_modificacion=array_Rol[index]['usuario_modificacion']
rol_temp.numero=array_Rol[index]['prontuario']
this.roles.push(rol_temp)
this.showCondenadoCard=true
}
}
if ('MontosPena' in obj)
{
let array_MP=obj['MontosPena']
if(isDevMode()) console.log("block ejecucion")
for (let index = 0; index < array_MP.length; index++) {
let ejecucion_temp= new Ejecucion();

ejecucion_temp.id=array_MP[index]['id']
ejecucion_temp.persona=array_MP[index]['persona']
ejecucion_temp.sentencia=array_MP[index]['sentencia']
ejecucion_temp.concepto=array_MP[index]['concepto']
ejecucion_temp.dia=array_MP[index]['dia']
ejecucion_temp.mes=array_MP[index]['mes']
ejecucion_temp.anio=array_MP[index]['anio']
ejecucion_temp.campo_abierto=array_MP[index]['campo_abierto']
ejecucion_temp.fecha_creacion=array_MP[index]['fecha_creacion']
ejecucion_temp.usuario_carga=array_MP[index]['usuario_carga']
ejecucion_temp.fecha_modificacion=moment(array_MP[index]['fecha_modificacion']).format('LLL')
ejecucion_temp.usuario_modificacion=array_MP[index]['usuario_modificacion']
ejecucion_temp.numero=array_MP[index]['numero']
this.ejecuciones.push(ejecucion_temp)

//Busca PDF Sentencia
this.documentService.getIDs(this.filters['letra'],this.filters['numero'],this.filters['anio']).subscribe(r=>{

    if(isDevMode()) console.log("result from actuaciones digitalizadas")
    if(isDevMode()) console.log(r);
    let temp_arr=[]
    
    for(let a=0;a<r.length;a++)
    {
        if(r[a]['categoria']['categoria']=="Sentencia"){

            let temp_obj={}
            temp_obj['archivo']=r[a]['archivo']
            temp_obj['categoria']=r[a]['categoria']['actuacion']
            temp_obj['descripcion']=r[a]['descripcion']
            temp_obj['es_copia']=r[a]['es_copia']
            temp_obj['fecha_creacion']=r[a]['fecha_creacion']
            temp_obj['fecha_modificacion']=r[a]['fecha_modificacion']
            temp_obj['id']=r[a]['id']
            temp_obj['numero']=r[a]['numero']
            temp_obj['orden']=r[a]['orden']
            temp_obj['situacion']=r[a]['situacion']
            temp_obj['tipo']=r[a]['categoria']['categoria']
            temp_obj['usuario_carga']=r[a]['usuario_carga']
            temp_obj['usuario_modificacion']=r[a]['usuario_modificacion']
            temp_arr.push(temp_obj)
                }
    }
    temp_arr.sort(function(a, b) {
      return a.orden - b.orden;
  });
  
    this.pdfsSentencia=temp_arr
    if(isDevMode()) console.log(this.pdfsSentencia[0]['descripcion'])
  
    
   })

}

}

if ('SentenciaEjecucion' in obj){
this.sentencias = obj['SentenciaEjecucion']

}

if ('DatosCondena' in obj)
{
this.datosCondenas=[]
let array_cd=obj['DatosCondena']
let today=new Date()
const oneDay = 24 * 60 * 60 * 1000;
if(isDevMode()) console.log("cargando datos condena")
if(isDevMode()) console.log(array_cd);
for (let index = 0; index < array_cd.length; index++) {
if(isDevMode()) console.log("iterating though")
if(isDevMode()) console.log(array_cd);
let dc_temp= new DatosCondena();
dc_temp.id=array_cd[index]['id']
dc_temp.persona=array_cd[index]['persona']
dc_temp.tipo_condena=array_cd[index]['tipo_condena']
dc_temp.fecha_de_cumplimiento=array_cd[index]['fecha_de_cumplimiento']
let fecha_de_cumplimiento=new Date(dc_temp.fecha_de_cumplimiento)
let fechaCumpl = moment(array_cd[index]['fecha_de_cumplimiento'])
dc_temp.fecha_de_cumplimiento=fechaCumpl.format("DD/MM/YYYY")

//---finaliza condena
let fecha = moment(fechaCumpl)
let fecha_fin = this.calcularTiempo(fecha)
if(isDevMode()) console.log(fecha_fin)
//-----

const diffDays = Math.round(Math.abs((today.getTime() - new Date(fecha_de_cumplimiento).getTime()) / oneDay));
//dc_temp.finaliza_fecha_cumplimiento=diffDays
dc_temp.finaliza_fecha_cumplimiento=fecha_fin
if(isDevMode()) console.log(dc_temp.finaliza_fecha_cumplimiento.anios+'-------------!!!!!!! finaliza')
dc_temp.fecha_libertad_condicional=array_cd[index]['fecha_libertad_condicional']
dc_temp.prision_perpetua=array_cd[index]['prision_perpetua']
dc_temp.reincidencia=array_cd[index]['reincidencia']
dc_temp.ley=array_cd[index]['ley']
dc_temp.art27_4=array_cd[index]['art27_4']
dc_temp.art27_8=array_cd[index]['art27_8']
dc_temp.art27_10=array_cd[index]['art27_10']
dc_temp.periodo=array_cd[index]['periodo']
dc_temp.fase=array_cd[index]['fase']
dc_temp.fecha_creacion=array_cd[index]['fecha_creacion']
dc_temp.usuario_carga=array_cd[index]['usuario_carga']
dc_temp.fecha_modificacion=moment(array_cd[index]['fecha_modificacion']).format('LLL')
dc_temp.usuario_modificacion=array_cd[index]['usuario_modificacion']
dc_temp.numero=array_cd[index]['numero']
this.datosCondenas.push(dc_temp)
} 
if(isDevMode()) console.log("final DC is")
if(isDevMode()) console.log(this.datosCondenas);
this.showDatosDeCondenaCard=true

}

}

load_delitos(obj){

    if (obj['Delitos'].length>0){
      let delitos_array: Delito[] = obj['Delitos']//array con delito-persona
        

    let repetidos = this.toFindDuplicates(delitos_array);

    let indicesSingle=[];

    console.log('longitud arreglo defensores '+delitos_array.length)   

    if(repetidos!=undefined){
        repetidos.forEach(rep => {

        let indices  = rep['indices']
        console.log(indices)
        
        let delitoPers_tmp ={} 
        delitoPers_tmp['persona'] =rep['persona']
        let delitos_tmp = []
        let elementInd = 0;
        
        for(let i=0; i<indices.length; i++){

          /*   if (i === 0){
                elementInd = indices[i]
            }else{
                elementInd = indices[i]-1
            } */
            elementInd = indices[i]


            let delito = {} // tipo defensa, defensor           
            console.log(indices.length)
            console.log('longitud ' +delitos_array.length)
            console.log('elemento '+elementInd)
            delito['delito'] = delitos_array[elementInd]['delito']
            delito['tipo'] = delitos_array[elementInd]['categoria']

            delitos_tmp.push(delito)    
            indicesSingle=[...indicesSingle, indices[i]]
            //delitosSingle.slice(elementInd-i)  
        }

        delitoPers_tmp['delitos']=delitos_tmp
        this.delitos.push(delitoPers_tmp)
    }); 

    if(isDevMode()) console.log("arreglo defensores"+JSON.stringify(delitos_array))

    for(let i=0; i<indicesSingle.length; i++){
        delitos_array.splice(indicesSingle[i]-i)
    }}

    delitos_array.forEach(del => {
        let delitosPers_tmp ={} 
        delitosPers_tmp['persona'] =del['persona']
        let delitos_tmp = []        

        let delito = {} // tipo defensa, defensor           

        delito['delito'] = del['delito']
        delito['tipo'] = del['categoria']
            
        delitos_tmp.push(delito)  

        delitosPers_tmp['delitos']=delitos_tmp
        this.delitos.push(delitosPers_tmp)
    }); 

    if(isDevMode()) console.log(this.delitos)

    }
}

load_delitos1(obj){

if (obj['Delitos'].length>0){
let delitos_array: Delito[] = obj['Delitos']//array con delito-persona
this.toFindDuplicates(delitos_array)
let delito_tmp={}//Objeto temporal con delitos por c/persona
let imputado = ''//temporal imputado para saber si es el mismo que el del siguiente objeto
let delitoArray_tmp = []//array de delitos de un imputado

for (let index = 0; index < delitos_array.length; index++) {
let persona = delitos_array[index]['persona'] 
let delito = {}
delito['delito'] = delitos_array[index]['delito']
delito['tipo'] = delitos_array[index]['categoria']
if(isDevMode()) console.log(delito['delito'])
let countDelitos;

if (imputado != persona){
if(isDevMode()) console.log('uno')

countDelitos = 0
imputado = persona
delito_tmp['persona']=imputado
delitoArray_tmp = []
delitoArray_tmp.push(delito) 
if (index+1==delitos_array.length){//si el array solo tiene un objeto
if(isDevMode()) console.log('sale')
delito_tmp['delitos']=delitoArray_tmp
this.delitos.push(delito_tmp)
}
}else{
if(imputado != ''){
if(isDevMode()) console.log('dos')
delitoArray_tmp.push(delito)
countDelitos++; 
} 
}
if(isDevMode()) console.log(index)
if(isDevMode()) console.log(delitos_array.length)
if (countDelitos != 0 && index+1==delitos_array.length){
if(isDevMode()) console.log('tres') 
delito_tmp['delitos']=delitoArray_tmp
this.delitos.push(delito_tmp)
}
} 
}
}

load_recurso(obj){

    this.load_magistrado(obj);

    this.recurso = obj['Expediente'][0]

    if(isDevMode()) console.log("RECURSO::::::!!!" +obj['Expediente'][0])
    let m = moment(this.recurso.fecha_de_creacion) 
    this.f_ingreso = moment(m).format("DD/MM/YYYY");
    this.creadoRecurso = this.calcularTiempo(m)
    if(isDevMode()) console.log(this.creadoRecurso)
    let fechaEtapa = moment(this.recurso.fecha_etapa, "DD/MM/YYYY HH:mm") 
    this.recursoTiempoEtapa = this.calcularTiempo(fechaEtapa)
    if(isDevMode()) console.log(this.recursoTiempoEtapa)
    //if(isDevMode()) console.log(m)
    if(isDevMode()) console.log('You are '+m.fromNow() + ' old');

    //Si es recurso, busco el principal 
    let filters= new FiltersLegajoItem();
    filters.letra=this.recurso.letra_ppal
    filters.anio= this.recurso.anio_ppal
    filters.numero=this.recurso.numero_expediente_ppal

    if(isDevMode()) console.log(filters.anio +' '+filters.letra+' '+filters.numero)
    if(isDevMode()) console.log(" Carga expediente principal")

    this.tablaService.getExpedientes(this.origin,filters).subscribe(r=> 
    { 
        if(isDevMode()) console.log(r)
        this.load_expediente(r) 
        this.load_delitos(r) 
        this.load_tablasParticipantes(r);
        this.load_defensaTecnica(r);
        this.load_HabeasCorpus(r);
        this.load_ejecucion(r);
        this.load_magistrado(r);
        this.load_hechos(r);
        this.load_alertaImputados(r);
        this.load_dependientes(filters)
    },
    error =>{

});
}

load_expediente(obj){

    this.isLoading_Principal=false; 
    if(isDevMode()) console.log("expte orig "+this.expediente)
    this.expediente = obj['Expediente'][0]
    if(isDevMode()) console.log(obj['Expediente'][0])
    let m = moment(this.expediente.fecha_de_ingreso) 
    this.f_ingreso = moment(m).format("DD/MM/YYYY");
    let creacion = moment(this.expediente.fecha_creacion, "DD/MM/YYYY HH:mm")
    console.log("fecha de creacion " +creacion)
    this.creado = this.calcularTiempo(moment(creacion))
    if(isDevMode()) console.log(m)
    let fechaEtapa = moment(this.expediente.fecha_etapa, "DD/MM/YYYY HH:mm") 
    this.fecha_etapa = this.calcularTiempo(fechaEtapa)
    if(isDevMode()) console.log(this.fecha_etapa)
    //if(isDevMode()) console.log(m)
    if(isDevMode()) console.log('You are '+m.fromNow() + ' old');

    //Si es acumulado, busco el principal 
    if(this.expediente.tipo_expediente!="Principal"){
    let filters= new FiltersLegajoItem();
    filters.letra=this.expediente.letra_ppal
    filters.anio= this.expediente.anio_ppal
    filters.numero=this.expediente.numero_expediente_ppal

    if(isDevMode()) console.log(filters.anio +' '+filters.letra+' '+filters.numero)
    if(isDevMode()) console.log(" Carga expediente principal")

    this.tablaService.getExpedientes(this.origin,filters).subscribe(r=> 
    { 
        if(isDevMode()) console.log(r)
        /* this.load_expediente(r) 
        this.load_delitos(r) 
        this.load_tablasParticipantes(r);
        this.load_defensaTecnica(r);
        this.load_HabeasCorpus(r);
        this.load_ejecucion(r);
        this.load_magistrado(r);
        this.load_hechos(r);
        this.load_alertaImputados(r); */
        let magistradoPrincipal=this.load_magistrado(r);
        this.load_dependientes(filters)
    },
    error =>{

});}


}

load_magistrado(obj){

    if(isDevMode()) console.log("Carga magistrados")
    if(isDevMode()) console.log(obj)


    let letra = obj['Expediente'][0]['letra']
    let tipo_exp = obj['Expediente'][0]['tipo_expediente']

    if ('Magistrado' in obj){
    let array_Magistrado=obj['Magistrado']
    for (let index = 0; index < array_Magistrado.length; index++) {
    let magistrado_temp = new Magistrado();

    magistrado_temp.id=array_Magistrado[index]['id']
    magistrado_temp.categoria=array_Magistrado[index]['categoria']
    magistrado_temp.juez=array_Magistrado[index]['juez']
    magistrado_temp.fecha_creacion=array_Magistrado[index]['fecha_creacion']
    magistrado_temp.usuario_carga=array_Magistrado[index]['usuario_carga']
    magistrado_temp.numero=array_Magistrado[index]['numero']
    
    if(isDevMode()) console.log(this.letra_expediente)
    if(isDevMode()) console.log(letra)
    if(this.letra_expediente != 'R' || letra != 'R'){

        if(tipo_exp != "Acumulado"){this.magistradosPpal.push(magistrado_temp)}else{
        if(isDevMode()) console.log("Carga magistrados principal")

    this.magistrados.push(magistrado_temp)}}else{
        if(isDevMode()) console.log("Carga magistrados recursos")

        this.magistradosRecurso.push(magistrado_temp)}
    }
}
}

calcularTiempo(fecha){

var a = moment();
var b = fecha;

if(isDevMode()) console.log("fecha actual "+ a)
if(isDevMode()) console.log("fecha creacion "+b)

var years = a.diff(b, 'year');
b.add(years, 'years');

var months = a.diff(b, 'months');
b.add(months, 'months');

var weeks = a.diff(b, 'weeks');
if(isDevMode()) console.log(weeks)
b.add(weeks, 'weeks');

var days = a.diff(b, 'days');
b.add(days, 'days')

var hours = a.diff(b, 'hours')
b.add(hours, 'hours')

var minutes = a.diff(b, 'minutes')

//if(isDevMode()) console.log(years + ' años ' + months + ' meses ' + weeks + ' semanas ' + days + ' dias' + hours + ' horas ' + minutes + ' minutos');
//if(isDevMode()) console.log(b)
// 8 years 5 months 2 days
let tiempo = {} 
tiempo['anios'] = Math.abs(years);
tiempo['meses'] = Math.abs(months);
tiempo['semanas'] = Math.abs(weeks);
tiempo['dias'] = Math.abs(days);
tiempo['horas'] = Math.abs(hours);
tiempo['minutos'] = Math.abs(minutes);

var tiempoStr

if (tiempo['anios'] > 0){
    tiempoStr = tiempo['anios']+ ' anios, ' 
    if (tiempo['meses']>0){ 
    tiempoStr = tiempoStr+ tiempo['meses']+' meses'
    }else if (tiempo['semanas']>0){ 
    tiempoStr = tiempoStr+ tiempo['semanas']+' semanas'
    }else if (tiempo['dias']>0){ 
    tiempoStr = tiempoStr+ tiempo['dias']+' dias'
    }else if(tiempo['horas']>0){ 
    tiempoStr = tiempoStr + tiempo['horas'] + ' horas'
    }else{
    tiempoStr = tiempoStr + tiempo['minutos'] + ' minutos'
} 
}else if(tiempo['anios'] < 1 && tiempo['meses'] > 0){
tiempoStr = tiempo['meses']+ ' meses, ' 
if (tiempo['semanas']>0){ 
tiempoStr = tiempoStr+ + tiempo['semanas'] +' semanas'
}else if(tiempo['dias']>0){ 
tiempoStr = tiempoStr+ + tiempo['dias'] +' dias'
}else if(tiempo['horas']>0){ 
tiempoStr = tiempoStr + tiempo['horas'] + ' horas'
}else{
tiempoStr = tiempoStr + tiempo['minutos'] + ' minutos'
} 
}else if(tiempo['anios'] < 1 && tiempo['meses'] < 1 && tiempo['semanas'] > 0){
tiempoStr = tiempo['semanas']+ ' semanas, ' 
if (tiempo['dias']>0){ 
tiempoStr = tiempoStr + tiempo['dias'] + ' dias'
}else if(tiempo['horas']>0){ 
tiempoStr = tiempoStr + tiempo['horas'] + 'horas'
}else{
tiempoStr = tiempoStr + tiempo['minutos'] + 'minutos'
} 
}else if(tiempo['anios'] < 1 && tiempo['meses'] < 1 && tiempo['semanas'] < 1 && tiempo['dias'] > 0){
tiempoStr = tiempo['dias']+ ' dias, ' 
if (tiempo['horas']>0){ 
tiempoStr = tiempoStr + tiempo['horas'] + ' horas'
}else{ 
tiempoStr = tiempoStr + tiempo['minutos'] + 'minutos'
} 
}else if(tiempo['anios'] < 1 && tiempo['meses'] < 1 && tiempo['semanas'] < 1 && tiempo['dias'] && tiempo['horas'] > 0){
tiempoStr = tiempo['horas']+ ' horas' + tiempo['minutos'] + ', minutos'
}else if(tiempo['anios'] < 1 && tiempo['meses'] < 1 && tiempo['semanas'] < 1 && tiempo['dias'] && tiempo['horas'] < 1 && tiempo['minutos'] > 0){
tiempoStr = tiempo['minutos'] + ' minutos' 
}else{
//if(isDevMode()) console.log(tiempo['anios'] < 1 && tiempo['meses'] > 0)
tiempoStr = 'Sin datos disponibles' 
} 

//if(isDevMode()) console.log(tiempoStr)
return (tiempoStr)
}

load_tareas(obj){
if ('Tareas' in obj){

this.tareas = obj['Tareas']
let ultimo_id = 0;

this.tareas.forEach(tarea => {
if (ultimo_id < tarea.id){
ultimo_id = tarea.id;
this.tareaActual = tarea;
let fecha = tarea.fecha_creacion
if(isDevMode()) console.log(fecha)
this.tareaActual.fecha_creacion = moment(fecha).format("DD/MM/YYYY HH:mm")
}
});

/* if ('Rol' in obj){
let roles = obj['Rol']
if(isDevMode()) console.log(this.alertaImputados)
roles.forEach(rol => {
if(isDevMode()) console.log(rol.rol)
if(isDevMode()) console.log(rol.rol)

if (rol.rol == 'Imputado' && (rol.situacion_personal == 'Detenido' || rol.situacion_personal == 'Domiciliaria')){
this.alertaImputados = true
} 
}); 
if(isDevMode()) console.log(this.alertaImputados)
} */
}
}

load_alertaImputados(obj){

    if ('Rol' in obj){
        let roles = obj['Rol']
        if(isDevMode()) console.log(this.alertaImputados)
        roles.forEach(rol => {
        if(isDevMode()) console.log(rol.rol)
        if(isDevMode()) console.log(rol.rol)
        
        if (rol.rol == 'Imputado' && (rol.situacion_personal == 'Detenido' || rol.situacion_personal == 'Domiciliaria')){
        this.alertaImputados = true
        } 
        }); 
        if(isDevMode()) console.log(this.alertaImputados)
        }
}

load_tablasParticipantes(obj){

let roles = obj['Rol']

roles.forEach(rol => {
    let fecha_modificacion = rol.rol['fecha_modificacion']
    if(fecha_modificacion!=undefined){
    rol.rol['fecha_modificacion']=moment(fecha_modificacion).format('LLL')
    }
switch (rol.rol) {
case 'Imputado':
this.imputados.push(rol)
break;
case 'Víctima':
this.victimas.push(rol) 
break;
case 'Perito':
this.peritos.push(rol) 
break;
} 
});
if(isDevMode()) console.log('peritos expte '+this.peritos)
if(isDevMode()) console.log('victimas expte '+this.victimas)
if(isDevMode()) console.log('imputados expte '+this.imputados)
}

isObjectEmpty(obj) {
if(isDevMode()) console.log('is empty '+ obj)
return !!Object.keys(obj).length;
}



load_defensaTecnica(obj){

    if (obj['DefensorTecnico'].length>0){

    let defensores_array: DefensorTecnico[] = obj['DefensorTecnico']//array con delito-persona

    let repetidos = this.toFindDuplicates(defensores_array);

    let indicesSingle=[];

    console.log('longitud arreglo defensores '+defensores_array.length)
    console.log('longitud arreglo def tecnico '+obj['DefensorTecnico'].length)

    console.log('elemento 3 arreglo defensores'+defensores_array[3])

    if(repetidos!=undefined){
        repetidos.forEach(rep => {

        let indices  = rep['indices']
        
        let defensaPers_tmp ={} 
        defensaPers_tmp['persona'] =rep['persona']
        let defensores_tmp = []
        let elementInd = 0;
        
        for(let i=0; i<indices.length; i++){

            /* if (i === 0){
                elementInd = indices[i]
            }else{
                elementInd = indices[i]-i
            } */

            elementInd = indices[i]

            let defensa = {} // tipo defensa, defensor
            console.log("element Indice "+elementInd)
            if(defensores_array[elementInd]['defensor_particular']!= null){

                defensa['tipo']='particular'
                defensa['defensor'] = defensores_array[elementInd]['defensor_particular']
                }else{
                defensa['tipo']='oficial'
                defensa['defensor'] = defensores_array[elementInd]['defensor_oficial']
            }
            defensores_tmp.push(defensa)    
            indicesSingle=[...indicesSingle, elementInd]
            //defensoresSingle.splice(elementInd)  
        }

        defensaPers_tmp['defensores']=defensores_tmp
        this.defensores.push(defensaPers_tmp)
    }); 

    if(isDevMode()) console.log("arreglo defensores"+JSON.stringify(defensores_array))

    for(let i = 0; i < indicesSingle.length; i++){
        defensores_array.splice(indicesSingle[i]-i)
        
    }
    }

    console.log("arreglo final "+JSON.stringify(defensores_array))
    defensores_array.forEach(def => {

        let defensaPers_tmp ={} 
        defensaPers_tmp['persona'] =def['persona']
        let defensores_tmp = []        

            let defensa = {} // tipo defensa, defensor

            if(def['defensor_particular']!= null){

                defensa['tipo']='particular'
                defensa['defensor'] = def['defensor_particular']
                }else{
                defensa['tipo']='oficial'
                defensa['defensor'] = def['defensor_oficial']
            }
            defensores_tmp.push(defensa)  

        defensaPers_tmp['defensores']=defensores_tmp
        this.defensores.push(defensaPers_tmp)
    }); 

    if(isDevMode()) console.log(this.defensores)

    }
}


load_hechos(obj){
if (obj['Hechos'].length > 0){
this.hechos = obj['Hechos'][0]

let fechaHechos = moment(this.hechos['fecha']).format('LL')
this.hechos['fecha'] = fechaHechos

if(isDevMode()) console.log('!!!!!!!!!!'+this.hechos['fecha'])
}
}

//------ Paginación audiencias 

load_audiencias_futuras(exp, page, fechaAct, rowsFuturas){

    this.audiencias_futuras=[];
    this.fechaActual = moment().format('ddd L')
    
    // if(isDevMode)(fechaAct = '01-02-2021')
    
    this.detalleExpedienteService.getAudienciasFuturas(exp, page, fechaAct, rowsFuturas).subscribe(r=>{

    if (r['data']!=undefined && r['data']!='N'){

        this.audiencias =r['data']  
            
        this.audiencias.forEach(audiencia => {
            //let fechaAud = moment(audiencia.fecha_audiencia)
            let fechaAud = audiencia.fecha_audiencia
            
            let aud = {}
            let fiscalesAud: Array<any> = []
            let defensoresAud: Array<any> = []
            let juecesAud: Array<any> =[]
            
            
            // if (fechaAud>=fechaAct){
            this.fechaAudiencia = moment(audiencia.fecha_audiencia ).format('ddd L')
            aud['audiencia'] = audiencia 
            aud['fecha'] = this.fechaAudiencia 
            //aud['tiempoAudiencia'] = this.calcularTiempo(moment(this.fechaAudiencia))
            // Jueces audiencia
            audiencia.juez.forEach(juez => {
            juecesAud.push(juez.nombre) 
            });
            
            aud['juez'] = juecesAud 
            
            // Fiscales audiencia
            audiencia.fiscal.forEach(fiscal => {
            fiscalesAud.push(fiscal.nombre) 
            });
            
            aud['fiscal'] = fiscalesAud 
            // Defensores audiencia
            audiencia.defensor.forEach(defensor => {
            defensoresAud.push(defensor.nombre) 
            });
            
            aud['defensor'] = defensoresAud
            
            this.audiencias_futuras.push(aud)    
       
        }); 

        if(!this.showPagination){
            for (let index = 0; index <= 1; index++) {            
                if(!this.audProximasCargadas && this.audiencias_futuras[index]!=undefined) {
                    this.proximasAudiencias.push(this.audiencias_futuras[index]); 
                    if(this.proximasAudiencias.length==2){this.audProximasCargadas = true;
                    }else if(index==2){index-1}             
                }
            }
        }

        //--Pgination
        let ultimaPagina = {}
        if(!this.isLastPageFuturas){
            ultimaPagina = this.total_records_pagination(rowsFuturas, this.audiencias_futuras.length, page)  
            this.isLastPageFuturas = ultimaPagina['ultimaPag']
            this.totalRecordsFuturas = ultimaPagina['totalRecords']
        }

    }},
    error =>{
        if(isDevMode()) console.log("Ha ocurrido un error "+error)
        if(isDevMode()) console.log(error);
    });

    this.loading = false;
        
}

load_audiencias_pasadas(exp, page, fechaAct, rowsPasadas){
   
    this.audiencias_pasadas=[]

    // if(isDevMode)(fechaAct = '01-06-2020')

    
    this.detalleExpedienteService.getAudienciasPasadas(exp, page, fechaAct, rowsPasadas).subscribe(r=>{

    if (r['data']!=undefined && r['data']!='N'){

        this.audiencias_pasadas=r['data']

        let ultimaPagina = {}

        if(!this.isLastPagePasadas){
            ultimaPagina = this.total_records_pagination(rowsPasadas, this.audiencias_pasadas.length, page)
            this.isLastPagePasadas = ultimaPagina['ultimaPag']
            this.totalRecordsPasadas = ultimaPagina['totalRecords']
        }
        
        
    }
        },
        error =>{
        if(isDevMode()) console.log("Ha ocurrido un error "+error)
        if(isDevMode()) console.log(error);
        }
        );

        this.loading = false;
}

total_records_pagination(rows, length, page){
    
    let totalRec ={
        totalRecords: 0,
        ultimaPag: false,
    }
    
    if(rows== length){    
        totalRec['totalRecords'] = length*(page+1);  
    }else if(length<rows){ 
        totalRec['totalRecords'] = (rows*page )-rows + length;
            totalRec['ultimaPag'] = true;                       
        }else if(totalRec['totalRecords'] == 0){
            totalRec['totalRecords'] = length;
            totalRec['ultimaPag'] = true;           }             
    
    return totalRec;
}


loadPage(event: LazyLoadEvent) {
    let rows = 0;
    if(this.display){ 
        rows = this.rowsPasadas;
    }else if (this.audFuturas){ 
        rows = this.rowsFuturas;
    }

    if(event['first'] == 0 )  {
        this.page = 1;
        if(this.display){ 
            this.load_audiencias_pasadas(this.expteAudiencias, this.page, this.fechaActualAud, rows);
        }else if (this.audFuturas){ 
            this.load_audiencias_futuras(this.expteAudiencias, this.page, this.fechaActualAud, rows);}    
    }else{   
            console.log(event['first']+'event first')     
            this.page = Math.trunc(event['first']/ rows)+1
            this.loading = true;    
            if(this.display){            
                this.load_audiencias_pasadas(this.expteAudiencias, this.page, this.fechaActualAud, rows);
            }else if (this.audFuturas){ 
                this.load_audiencias_futuras(this.expteAudiencias, this.page, this.fechaActualAud, rows);
        }  
    
    }
}


formatoExpAudiencias(obj){

    let letra = obj['Expediente'][0]['letra']
    let expte = obj['Expediente'][0]['numero']
    let exp = expte.replace(/[^\w]/gi, '') //eliminar caracteres especiales y espacios 
    expte = exp.substring(letra.length) //número solo
    let ceros = 13 - letra.length
    expte = letra + expte.padStart(ceros, '0')

    return expte;
}


load_audiencias(obj){

let expte = obj['Expediente'][0]['numero']
this.fechaActual = moment().format('ddd L')

if(isDevMode()) console.log('audiencias'+expte)
this.detalleExpedienteService.getAudiencias(expte).subscribe(r=>{
if (r!=undefined && r[0]!='N'){

let audiencias:Array<Audiencia> =r
// if(isDevMode()) console.log('Audiencia'+r[0]['juez'][0]['Juez'])
//if(isDevMode()) console.log('Audiencia'+r[0]['juez'][0]['Juez'])
const fechaAct = moment().format('YYYY-MM-DD')

audiencias.forEach(audiencia => {
//let fechaAud = moment(audiencia.fecha_audiencia)
let fechaAud = audiencia.fecha_audiencia

let aud = {}
let fiscalesAud: Array<any> = []
let defensoresAud: Array<any> = []
let juecesAud:Array<any>=[]


if(isDevMode()) console.log('una audiencia'+audiencia.tipo)

if (fechaAud>=fechaAct){
this.fechaAudiencia = moment(audiencia.fecha_audiencia ).format('ddd L')
aud['audiencia'] = audiencia 
aud['fecha'] = this.fechaAudiencia 
aud['tiempoAudiencia'] = this.calcularTiempo(moment(this.fechaAudiencia))
// Jueces audiencia
audiencia.juez.forEach(juez => {
juecesAud.push(juez.Juez) 
});

aud['juez'] = juecesAud 

// Fiscales audiencia
audiencia.fiscal.forEach(fiscal => {
fiscalesAud.push(fiscal.Fiscal) 
});

aud['fiscal'] = fiscalesAud 
// Defensores audiencia
audiencia.defensor.forEach(defensor => {
defensoresAud.push(defensor.Defensor) 
});

aud['defensor'] = defensoresAud

if(isDevMode()) console.log("Fecha Audiencia "+fechaAud)
if(isDevMode()) console.log("fecha Actual "+ fechaAct);

this.audiencias_futuras.push(aud)

this.audiencias_futuras.sort((a, b) => {
return moment(moment(a.fecha)).diff(moment(b.fecha));
}); 

}else{
this.audiencias_pasadas.push(audiencia)
} 
},
error =>{
if(isDevMode()) console.log("Ha ocurrido un error "+error)
if(isDevMode()) console.log(error);
}
);

/**-->audiencia
Let obj aud {}
Aud.audiencia=audiencia
Foreach audiencia.juez
Let juez array.push(juez)
Aud.juez =juez
Aud_fut.push(aud) */
}}) 
}

/* document.getElementById('jueces');
juecesDom.innerHtml = r3JitTypeSourceSpan;
*/
/* public openFolder(event, item) {
if(isDevMode()) console.log(item)
if(item == 1){
this.carpetaOP2 = false;
this.carpetaOP3 = false;
if (this.carpetaOP1){
if(isDevMode()) console.log('cambiando.......')
this.carpetaOP1 = false;
}else{this.carpetaOP1=true;}
}else if (item==2){
this.carpetaOP1 = false;
this.carpetaOP3 = false;
if (this.carpetaOP2){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP2 = false;
}else{this.carpetaOP2=true;}
}else{
this.carpetaOP1 = false;
this.carpetaOP2 = false;
if (this.carpetaOP3){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP3 = false;
}else{this.carpetaOP3=true;}

}
} */

/* public openFolder(event, item) {
if(isDevMode()) console.log(item)
if(item == 1){
this.carpetaOP2 = false;
this.carpetaOP3 = false;
this.showViewer=false;
if (this.carpetaOP1){
if(isDevMode()) console.log('cambiando.......')
this.carpetaOP1 = false;
}else{this.carpetaOP1=true;}
}else if (item==2){
this.carpetaOP1 = false;
this.carpetaOP3 = false;
this.showViewer=false;
if (this.carpetaOP2){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP2 = false;
}else{this.carpetaOP2=true;}
}else{
this.carpetaOP1 = false;
this.carpetaOP2 = false;
this.showViewer=true
if (this.carpetaOP3){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP3 = false;
}else{this.carpetaOP3=true;}

}
} */

public openFolder(event, item) {
if(isDevMode()) console.log(item)
if(item == 1){
//xrays
this.carpetaOP2 = false;
this.carpetaOP3 = false;
this.showViewer=false;
this.showActualizacionesDigitalizadas=false;
this.showxrays=true;
if (this.carpetaOP1){
if(isDevMode()) console.log('cambiando.......')
this.carpetaOP1 = false;
}else{this.carpetaOP1=true;}
}else if (item==2){
//actualizaciones digitalizadas
this.carpetaOP1 = false;
this.carpetaOP3 = false;
this.showViewer=false;
this.showActualizacionesDigitalizadas=true;
this.showxrays=false;
if (this.carpetaOP2){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP2 = false;
}else{this.carpetaOP2=true;}
}else{
//3 visualizacion completa
this.carpetaOP1 = false;
this.carpetaOP2 = false;
this.showViewer=true;
this.showxrays=false;
this.showActualizacionesDigitalizadas=false;
if (this.carpetaOP3){
if(isDevMode()) console.log('cambiando.......') 
this.carpetaOP3 = false;
}else{this.carpetaOP3=true;}

}
}


showModal(){
let myModal = document.getElementById('myModal')
let myInput = document.getElementById('myInput')

myModal.addEventListener('shown.bs.modal', function () {
myInput.focus()
})
}

display: boolean = false;
audFuturas: boolean = false;

showDialog() {
if(isDevMode()) console.log('display')
this.display = true;
this.firstIndex = 0;
}
showDialogFuturas() {
    this.audFuturas = true;
    this.showPagination = true;
    this.firstIndex =0;
    }



verPers: boolean = false;

enableButton(){this.buttonPersona=false}

verPersona(dni) { 

this.buttonPersona = true;
this.verPers = false
this.isLoading_antecedentes = true;

if(dni != null){

if(isDevMode()) console.log('buscar datos persona')
this.origin = "persona"
let filters = new filtersPersona();
filters.documento = dni

this.tablaService.getExpedientes(this.origin,filters).subscribe(r=>{
if(isDevMode()) console.log('busqueda por persona.....'+JSON.stringify(r))

this.antecedentes = []
//Datos personales de la persona
this.persona = r['Datos personales'][0]

if(isDevMode()) console.log("Persona modal " + r['Datos personales'][0])

//Antecedentes de la persona en el sistema (exptes relacionados)
let roles = r['Roles'] 

if(isDevMode()) console.log(roles[0]) 
if(isDevMode()) console.log(roles.length) 
if(isDevMode()) console.log(roles.length + "roleees longitud antes del for")

if (roles.length >1){
if(isDevMode()) console.log('ingreso al for')

for(let index = 0; index<roles.length; index++){ 

if(isDevMode()) console.log(roles[index]) 
if(isDevMode()) console.log(index)
if(isDevMode()) console.log(this.expediente.numero_expediente +' '+ roles[index].numero_expediente)
//if(roles[index].numero_expediente != undefined){

//if(this.expediente.numero_expediente != roles[index].numero_expediente){
if(this.expediente.numero_expediente != roles[index].numero.numero_expediente){
if(isDevMode()) console.log('ingreso al if')
if(isDevMode()) console.log(roles[index].numero.numero_expediente)

let filters = new FiltersLegajoItem();
filters.numero = roles[index].numero.numero_expediente;
filters.anio=roles[index].numero.anio;
filters.letra=roles[index].numero.letra;
this.antecedentes = []

this.tablaService.getExpedientes('legajo', filters).subscribe(r=>{
let expediente:Expediente = r['Expediente'][0] 
let antecedente ={} 
antecedente['expediente'] = expediente;
antecedente['rol']=roles[index].rol 
this.antecedentes.push(antecedente)

if(isDevMode()) console.log('length '+ roles.length + ' index ' + index)
if((roles.length-1) == index){
if(isDevMode()) console.log('salgo 1')
this.verPers = true
this.isLoading_antecedentes = false;
} 
})
}else{
if((roles.length-1) == index){
if(isDevMode()) console.log('salgo 1')
this.verPers = true
this.isLoading_antecedentes = false;

} }
}
}else{
if(isDevMode()) console.log('sin otros expedientes ' + roles.length)
this.verPers = true
this.isLoading_antecedentes = false;

}

},
error =>{}
);

}else{ alert('La persona no tiene DNI cargado para poder consultar')}

}

navigate2Detail(anio,letra,numero_expediente)
{
//this.router.navigate(['/dash/detalle-expediente',letra,numero_expediente,anio,this.origin]);

const url = this.router.serializeUrl(this.router.createUrlTree(['/dash/detalle-expediente',letra,numero_expediente,anio,this.origin]));
window.open(url, '_blank'); 
//this.verPers = false
}

loadSelected(id)
  {
    this.documentService.getDocument(id).subscribe((r: Blob)=>
      {
        if(isDevMode()) console.log("pdf buscado" +JSON.stringify(r))
        this.selected=r

        for(let i=0; i<this.pdfsSentencia.length; i++){
            if (this.pdfsSentencia[i]['id']==id){
                this.pdfDetail = this.pdfsSentencia[i]
            }
        }
        if(isDevMode()) console.log(this.selected)
        this.showPDF = true;

      })
    }

    

    toFindDuplicates(element) {
        let arry = element;
        let resultToReturn = false;
        let arrySearch = []
        let indices = []
        let repetido = {} //persona, indices[]
        for (let i = 0; i < arry.length; i++) { // nested for loop
            for (let j = 0; j < arry.length; j++) {                
                // prevents the element from comparing with itself                
                if (i !== j) {
                    // check if elements' values are equal
                    if (arry[i]['persona'] === arry[j]['persona']) {
                        // duplicate element present    
                        //defensoresPersona = 
                        indices.push(i)
                        indices.push(j)
                        console.log(arry[i]['persona'])
                        //arrySearch.push(j)
                        resultToReturn = true;
                        // terminate inner loop                        
                    }                    
                }
            }
            if (indices.length>0){
                repetido['persona']=arry[i]['persona']
                indices = indices.sort()
                repetido['indices']= [...new Set(indices)];
                indices
                arrySearch.push(repetido)
                indices=[]
                repetido = {}
            }
            // terminate outer loop                                                                                  
            
        }
        if(resultToReturn) {
                console.log('Duplicate elements exist');
                let indicesRep = []
                // 742987/19
                
                for (let i = 0; i < arrySearch.length; i++) { // nested for loop
                    for (let j = i+1; j < arrySearch.length; j++) {                
                        // prevents the element from comparing with itself                
                        if (i !== j) {
                            // check if elements' values are equal
                            if (arrySearch[i]['persona'] === arrySearch[j]['persona']) {
                                console.log('Duplicate elements existentes');
                                //Delete duplicate element
                                // arrySearch.splice((longitud-1),1);
                                indicesRep.push(j)
                            }                    
                        }
                    }                
                }

                let indicesUnicos = [...new Set(indicesRep)];
                for(let i = 0; i < indicesUnicos.length; i++){
                    arrySearch.splice((indicesUnicos.length-i),1);
                }

                return arrySearch;

        }else {
                console.log("Duplicates don't exist ");
                    }
                }

}


