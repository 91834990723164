<div class="card cuerpo card-cuerpo" id="grad1">
    <div class="card-header">
        <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div *ngIf='!isLoading_informacionGeneral' style="text-align: center; color: white;">
            <span *ngIf="letra_expediente != 'R'; else esRecurso">Caso N° {{expediente.numero}} caratulado "{{expediente.caratula}}"</span>
            <ng-template #esRecurso><span>Caso N° {{recurso.numero}} caratulado "{{recurso.caratula}}"</span></ng-template>
            
        </div>
    </div>

    <!-------->  

    <ul class="list-group list-group-horizontal-sm list-group-item-light bg-light ulCollapse"
        style="padding:10px; background: white;">
        <li class="list-group-item list-group-item-ligth text-center liCollapse" >
            <a data-toggle="collapse" data-parent="#content" href="#InfoExpe" role="button" aria-expanded="true"
                aria-controls="InfoExpe" id="flecha1" style="color:#333;font-size:14px;"
                (click)="openFolder($event, 1)"><span style="font-family: 'Montserrat', sans-serif"><b>Información del
                        caso </b></span> 
                         <i  id="flecha2_icon" [ngClass]="(carpetaOP1==true)?'fa fa-folder-open-o fa-lg':'fa fa-folder-o fa-lg'"></i>
            </a>
        </li>
        <li class="list-group-item list-group-item-ligth text-center liCollapse" >
            <a data-toggle="collapse" href="#actuaciones" role="button" aria-expanded="false"
                aria-controls="Actuaciones" id="flecha2" style="color:#333;font-size:14px;"
                (click)="openFolder($event, 2)">
                <span style="font-family: 'Montserrat', sans-serif"><b>
                    Actuaciones digitalizadas </b></span>
                     <i  id="flecha2_icon" [ngClass]="(carpetaOP2==true)?'fa fa-folder-open-o fa-lg':'fa fa-folder-o fa-lg'"></i>
            
            </a>

        
        </li>
        <li class="list-group-item list-group-item-ligth text-center liCollapse" >
            <a data-toggle="collapse" style="color:#333;font-size:14px;" href="#ver_online" role="button" id="flecha3"
                aria-expanded="false" aria-controls="ver_online" (click)="openFolder($event, 3)"><span
                    style="font-family: 'Montserrat', sans-serif"><b>Visualización completa </b></span> 
                    <i  id="flecha2_icon" [ngClass]="(carpetaOP3==true)?'fa fa-folder-open-o fa-lg':'fa fa-folder-o fa-lg'"></i>
            </a>
        </li>
    </ul>

    <!------>

    <div class="modal-body" id="content">
        <div id="InfoExpe" class="collapse multi-collapse collpase show" data-parent="#content">

            <!-- PRIMERA LINEA DE BLOQUES COMÚN A TODOS SALVO RECURSO-->
            <!-- ---------------------------------------->

            <div class="row row-detalles">            

                    <div class="col-md-8 col-xs-12 div-card" *ngIf="letra_expediente != 'R'; else Recurso" >
                        <div class="card card-detalles">
                            <div class="card-header">
                                <fa-icon [icon]="faInfo"></fa-icon>
                                | Información general
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul class="ul-cards">
                                        <li>
                                            <strong>
                                                <u *ngIf="(expediente.tipo_expediente == 'Principal'); else noPrincipal ">Legajo
                                                    Principal:</u>
                                                <ng-template #noPrincipal><u>Legajo {{expediente.tipo_expediente}}</u></ng-template>
                                            </strong>
                                        </li>                                                               
                                        <li><strong>Organismo: </strong>{{expediente.organismo}}</li>
                                        <li *ngIf="(expediente.tipo_expediente != 'Principal'); else juezPrincipal "><strong>Juez: </strong><span *ngFor="let magistrado of magistrados">{{magistrado.juez}} ({{magistrado.categoria}})</span></li>
                                        <ng-template #juezPrincipal>
                                        <li><strong>Juez: </strong><span *ngFor="let magistrado of magistradosPpal">{{magistrado.juez}} ({{magistrado.categoria}})</span></li>
                                        </ng-template>
                                        <li><strong>Proceso: </strong>{{expediente.proceso}}</li>
                                        <li><strong>Fecha de Ingreso: </strong>{{f_ingreso}}</li>
                                        <li><strong>Creado hace: </strong>{{creado}}</li>                    
                                        <hr>
                                        <li><strong>Etapa: </strong>{{expediente.etapa}} ({{fecha_etapa}})
                                        </li>
                                        <hr>
                                        <span class="ul-cards" *ngIf="(expediente.tipo_expediente != 'Principal' && conDependientes)">
                                            <li><strong><u>Legajo principal</u>: </strong></li>
                                            <li><strong>Número: </strong> 
                                                <a  
                                                    href="javascript:void(0)" 
                                                    (click)="navigate2Detail(expediente.anio_ppal,expediente.letra_ppal,expediente.numero_expediente_ppal); false" 
                                                    target="_blank" 
                                                    style="color: blue">                                    
                                                    {{expediente.principal}}
                                                </a>                                    
                                            </li>
                                            <li><strong>Organismo: </strong>{{expediente.organismo}}</li>
                                            <li><strong>Juez: </strong><span *ngFor="let magistrado of magistradosPpal">{{magistrado.juez}}</span></li>
                                            <hr>                                            
                                        </span>

                                        <li *ngIf="conDependientes, else sinDep">
                                            <div *ngFor="let dependiente of dependientes ">

                                            <div *ngIf="dependiente.letra!='R', else recursoDependiente">
                                            <div *ngIf="dependiente.numero!=expediente.numero">
                                            <strong><u>Acumulados: </u></strong>

                                            <a  
                                                href="javascript:void(0)" 
                                                (click)="navigate2Detail(dependiente.anio,dependiente.letra,dependiente.numero_expediente); false" 
                                                target="_blank" 
                                                style="color: blue">                                    
                                                {{dependiente.numero}}                                            
                                            </a> 
                                            <hr>
                                            </div>
                                        </div>

                                            <ng-template #recursoDependiente>
                                                <strong><u>Recursos: </u></strong>

                                                <li>
                                                <strong>Número: </strong>  
                                                <!-- <a *ngFor="let dependiente of dependientes ">  -->
                                                <a  
                                                href="javascript:void(0)" 
                                                (click)="navigate2Detail(dependiente.anio,dependiente.letra,dependiente.numero_expediente); false" 
                                                target="_blank" 
                                                style="color: blue">                                    
                                                {{dependiente.numero}}                                            
                                                </a>
                                                <!-- </a> -->
                                                </li>                                             
                                                <li>
                                                    <strong>Proceso: </strong>{{dependiente.tipo_expediente}}
                                                </li>
                                                <li>
                                                    <strong>Tipo: </strong>{{dependiente.motivo}}
                                                </li>
                                                <li>
                                                    <strong>Etapa: </strong>{{dependiente.etapa}}
                                                </li>
                                                <li>
                                                    <strong>Observación: </strong>{{dependiente.transferencia_campo_abierto}}
                                                </li>
    
                                            </ng-template> 
                                        </div> 
                                        </li> 
                                        <ng-template #sinDep>
                                            <!-- <li><strong>Creado hace: </strong>{{creado}}</li>      
                                            <hr> --> 
                                            <!-- <ul class="ul-cards">                                           
                                                <li><strong>Etapa: </strong>{{recurso.etapa}} ({{recursoTiempoEtapa}})</li>
                                                <hr>
                                            </ul>  -->                     
                                        </ng-template>   
                                    </ul>                               
                                    <ul class="ul-cards" *ngIf="(expediente.tipo_expediente != 'Principal' && !conDependientes)">
                                        <li><strong>Legajo principal: </strong>
                                        <li><strong>Número: </strong> 
                                            <a  
                                                href="javascript:void(0)" 
                                                (click)="navigate2Detail(expediente.anio_ppal,expediente.letra_ppal,expediente.numero_expediente_ppal); false" 
                                                target="_blank" 
                                                style="color: blue">                                    
                                                {{expediente.principal}}
                                            </a>                                    
                                        </li>
                                        <li><strong>Organismo: </strong>{{expediente.organismo}}</li>
                                        <li><strong>Juez: </strong><span *ngFor="let magistrado of magistrados">{{magistrado.juez}}</span></li>
                                        <hr>
                                        <li><strong>Etapa: </strong>{{expediente.etapa}} ({{fecha_etapa}})
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- CARD SI ES RECURSO-->
                <!-- CARD SI ES RECURSO-->
                <!-- CARD SI ES RECURSO-->
                <ng-template #Recurso>
                    <div class="col-md-8 col-xs-12" >
                        <div class="card card-detalles">
                            <div class="card-header">
                                <fa-icon [icon]="faInfo"></fa-icon>
                                | Información general
                            </div>
                            <div *ngIf='isLoading_Principal' class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div *ngIf='!isLoading_Principal'>
                                <ul class="ul-cards">                                
                                        <li ><strong><u>Recurso: </u></strong></li>
                                        <li><strong>Proceso: </strong>{{recurso.proceso}}</li>
                                        <li><strong>Tipo: </strong>{{recurso.motivo}}</li>
                                        <li><strong>Organismo: </strong>{{recurso.organismo}}</li>
                                        <li><strong>Creado hace: </strong>{{creadoRecurso}}</li>                    
                                        <li><strong>Etapa: </strong>{{recurso.etapa}} ({{recursoTiempoEtapa}})
                                        <li><strong>Juez: </strong><span *ngFor="let magistrado of magistradosRecurso">{{magistrado.juez}} ({{magistrado.categoria}})</span></li>
                                        <li><strong>Observaciones: </strong>{{recurso.transferencia_campo_abierto}}</li>
                                        <hr>
                                    <li>
                                        <strong>
                                            <u>Legajo Principal:</u>
                                        </strong>
                                    </li>     
                                    <li><strong>Número: </strong>
                                        <a  href="javascript:void(0)" (click)="navigate2Detail(expediente.anio,expediente.letra,expediente.numero_expediente); false" target="_blank" style="color: blue">                          
                                        {{expediente.numero}}</a></li> 
                                    <li><strong>Organismo: </strong>{{expediente.organismo}}</li>
                                    <li><strong>Juez: </strong><span *ngFor="let magistrado of magistradosPpal">{{magistrado.juez}} ({{magistrado.categoria}})</span></li>
                                    <li><strong>Proceso: </strong>{{expediente.proceso}}</li>
                                    <li><strong>Fecha de Ingreso: </strong>{{f_ingreso}}</li>
                                    <li *ngIf="conDependientes, else sinDep"><strong>|</strong>Acumulados: 
                                        <a *ngFor="let dependiente of dependientes ">
                                        <a  
                                            href="javascript:void(0)" 
                                            (click)="navigate2Detail(dependiente.anio,dependiente.letra,dependiente.numero_expediente); false" 
                                            target="_blank" 
                                            style="color: blue">                                    
                                            {{dependiente.numero}}                                            
                                        </a> 
                                        </a>
                                        <strong> Creado hace: </strong>{{creado}}
                                    </li>      
                                    <ng-template #sinDep>
                                        <li><strong>Creado hace: </strong>{{creado}}</li>      
                                        <!--<hr>  
                                            <ul class="ul-cards">                                           
                                            <li><strong>Etapa: </strong>{{recurso.etapa}} ({{recursoTiempoEtapa}})</li>
                                            <hr>
                                        </ul>   -->                    
                                    </ng-template>      
                                    <hr>
                                        <li><strong>Etapa: </strong>{{recurso.etapa}} ({{recursoTiempoEtapa}})
                                        </li> 
                                </ul>
                                                   
                            </div>
                        </div>
                    </div>
                </ng-template>
                
                <!----------------------------->
                <div id="div_tareas" class="col-md-4 col-xs-12 card-siguiente">
                    <div class="card card-detalles  ">
                        <div class="card-header" >
                            <fa-icon [icon]="faBriefcase"></fa-icon>
                            | Tareas
                        </div>
                        <div class="card-body">
                            <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div *ngIf='!isLoading_informacionGeneral'>
                                <ul class="ul-cards">
                                    <li><strong>Acción: </strong>{{tareaActual.accion}}</li>
                                    <li><strong>Tareas a realizar: </strong>{{tareaActual.descripcion_tareas}}</li>
                                    <li><strong>Responsable: </strong>{{tareaActual.responsable}}</li>
                                    <li><strong>Fecha de asignación: </strong>{{tareaActual.fecha_creacion}}</li>
                                </ul>
                                <div *ngIf="alertaImputados" style="color: red; font-style: oblique;">
                                    <hr>
                                    <span style="margin-left: 2%;">Tiene imputados detenidos</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- ---------------------------------------->
            <!--SEGUNDA LÍNEA DE BLOQUES               -->

            <!-- FILA EXCLUSIVA SI ES HABEAS CORPUS -->
            <!-- FILA EXCLUSIVA SI ES HABEAS CORPUS -->
            <!-- FILA EXCLUSIVA SI ES HABEAS CORPUS -->

            <div *ngIf='letra_expediente == "HC"'>

                <div class="row row-detalles">
                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUsers"></fa-icon>
                                | Datos del Habeas Corpus
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul class="ul-cards" *ngFor="let hc of habeasCorpuses">                                        
                                        <li><strong>Categoria: </strong>{{hc.categoria}}</li>
                                        <li><strong>Beneficiario: </strong>{{hc.beneficiario}}</li>
                                        <li><strong>Solicitante: </strong>{{hc.solicitante}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Legajos vinculados
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <div *ngFor="let vinculado of vinculadosHC">
                                        <a  href="javascript:void(0)" (click)="navigate2Detail(vinculado.anio,vinculado.letra,vinculado.numero); false" target="_blank" style="color: blue"> 
                                            - {{vinculado.legajo_asociado}}
                                        </a>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <div class="row">
                                    <div class="col-xl-6 col-md-12">
                                        <fa-icon [icon]="faCalendarCheck"></fa-icon>
                                        | Audiencias
                                    </div>
                                    <div class="col-md-12 col-xl-6" *ngIf="!isLoading_informacionGeneral && audiencias_pasadas.length!=0">
                                        <!-- Button trigger modal -->
                                        <button type="button" (click)="showDialog()"
                                            class="btn btn-outline-primary btn-sm"
                                            >
                                            Audiencias pasadas
                                            <fa-icon [icon]="faEye"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                
                                <div *ngIf='audiencias_futuras.length >0 || showPagination'>                                
                                    <ul *ngFor="let proximaAudiencia of proximasAudiencias" class="ul-cards">
                                        <li><strong> Hora: </strong>{{proximaAudiencia.audiencia.hora_audiencia}} </li>
                                        <li><strong> Tipo: </strong>{{proximaAudiencia.audiencia.tipo}} </li>
                                        <li><strong> Sala: </strong>{{proximaAudiencia.audiencia.sala}}</li>
                                        <!--<li *ngFor="let juez of audiencia"><strong> Juez: </strong>{{audiencia.juez}}</li>-->
                                        <li><strong> Juez: </strong>{{proximaAudiencia.juez}}</li>
                                        <li><strong> Fiscal: </strong>{{proximaAudiencia.fiscal}}</li>
                                        <li><strong> Defensor: </strong>{{proximaAudiencia.defensor}}</li>

                                        <li>
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card">
                                                            <div class="card-body">  
                                                                <div class="hori-timeline" dir="ltr" style="border-top: 3px solid #e9ecef;">
                                                                    <ul class="list-inline events" width="100%;">
                                                                    
                                                                    <li class="list-inline-item event-list">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{fechaActual}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-12">Hoy<br></h6>
                                                                        </div>
                                                                        </li> 
                                                                        <li class="list-inline-item event-list" style="float:right;">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{proximaAudiencia.fecha}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-16">Audiencia<br> </h6>
                                                                            <!-- <span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.dias > 0, else horas">{{proximaAudiencia.tiempoAudiencia.dias}} dias</span>
                                                                            <ng-template #horas><span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.horas > 0">{{proximaAudiencia.tiempoAudiencia.horas}} horas</span></ng-template>
                                                                            </h6> -->
                                                                        </div>
                                                                        </li>
                                                                    </ul>
                                                                    </div>  
                                                            </div>
                                                        </div>
                                                        <!-- end card -->
                                                    </div>
                                                </div>
                                            </div> 
                                        </li>
                                    </ul>    
                                    <div *ngIf='audiencias_futuras.length>2 || showPagination' class="col-md-12 col-xl-12" >
                                        <br>
                                        <p class="card-text" style="color: red">Existen más audiencias agendadas</p>
                                        <a 
                                            (click)="showDialogFuturas()"
                                            class="btn btn-primary"
                                        >Ver todas
                                        <fa-icon [icon]="faEye"></fa-icon>
                                        </a>                                            
                                    </div>                                        
                            </div>
                            <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (audiencias_futuras.length == 0 && !showPagination) && (!isLoading_informacionGeneral && audiencias_pasadas!=0)'>
                                    <div>No existen audiencias futuras en Agenda.
                                    <br> Puede ver audiencias pasadas presionando "Audiencias pasadas"</div>
                            </div>
                            <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (!isLoading_informacionGeneral && audiencias_pasadas==0)'>
                                <div>No existen audiencias para este expediente
                                </div>
                            </div>    
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row row-detalles">
                    <div class="col-md-3">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faBalanceScale"></fa-icon>
                                | Delitos
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul *ngFor="let delito of delitos" class="ul-cards">
                                        <li><strong>{{delito.persona}}: </strong> </li>
                                        <li *ngFor="let del of delito.delitos">• {{del.tipo}}: {{del.delito}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-9 card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <fa-icon [icon]="faCommentDots"></fa-icon>
                                | Hechos
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <li class="list-group-item no-line" *ngIf='hechos.visibilidad, else noVisible'>
                                        Fecha del Hecho: {{hechos.fecha}}
                                        <hr>
                                        {{hechos.hechos}}
                                        <ng-template #noVisible>
                                            <p class="text-primary">Hechos no visibles</p>
                                        </ng-template>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>

                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faMale"></fa-icon>
                                | Beneficiarios
                            </div>
                            <div *ngIf='audiencias_futuras.length >0 || showPagination'>
                                <ul *ngFor="let proximaAudiencia of proximasAudiencias" class="ul-cards">
                                    <li><strong> Hora: </strong>{{proximaAudiencia.audiencia.hora_audiencia}} </li>
                                    <li><strong> Tipo: </strong>{{proximaAudiencia.audiencia.tipo}} </li>
                                    <li><strong> Sala: </strong>{{proximaAudiencia.audiencia.sala}}</li>
                                    <!--<li *ngFor="let juez of audiencia"><strong> Juez: </strong>{{audiencia.juez}}</li>-->
                                    <li><strong> Juez: </strong>{{proximaAudiencia.juez}}</li>
                                    <li><strong> Fiscal: </strong>{{proximaAudiencia.fiscal}}</li>
                                    <li><strong> Defensor: </strong>{{proximaAudiencia.defensor}}</li>

                                    <li>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="card">
                                                        <div class="card-body">  
                                                            <div class="hori-timeline" dir="ltr" style="border-top: 3px solid #e9ecef;">
                                                                <ul class="list-inline events" width="100%;">
                                                                
                                                                <li class="list-inline-item event-list">
                                                                    <div class="px-6">
                                                                        <div class="event-date bg-soft-primary">
                                                                        <span style="font-size:10px;">{{fechaActual}}</span>
                                                                        </div>
                                                                        <h6 class="font-size-12">Hoy<br></h6>
                                                                    </div>
                                                                    </li> 
                                                                    <li class="list-inline-item event-list" style="float:right;">
                                                                    <div class="px-6">
                                                                        <div class="event-date bg-soft-primary">
                                                                        <span style="font-size:10px;">{{proximaAudiencia.fecha}}</span>
                                                                        </div>
                                                                        <h6 class="font-size-16">Audiencia<br> </h6>
                                                                        <!-- <span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.dias > 0, else horas">{{proximaAudiencia.tiempoAudiencia.dias}} dias</span>
                                                                        <ng-template #horas><span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.horas > 0">{{proximaAudiencia.tiempoAudiencia.horas}} horas</span></ng-template>
                                                                        </h6> -->
                                                                    </div>
                                                                    </li>
                                                                </ul>
                                                                </div>  
                                                        </div>
                                                    </div>
                                                    <!-- end card -->
                                                </div>
                                            </div>
                                        </div> 
                                    </li>
                                </ul>        
                            </div>
                            <ng-template><div #sinAudienciasFut>No existen as  a la Agenda.
                                <br> Para das seleccione "Ver"</div>
                            </ng-template> 
                        </div>
                    </div>
                </div>
            </div>




            <!-- FIN FILA EXCLUSIVA SI ES HABEAS CORPUS -->
            <!-- FIN FILA EXCLUSIVA SI ES HABEAS CORPUS -->
            <!-- FIN FILA EXCLUSIVA SI ES HABEAS CORPUS -->
            <!-- FIN FILA EXCLUSIVA SI ES HABEAS CORPUS -->

            <!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% -->

            <!-- FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FILA EXCLUSIVA SI ES EJECUCION -->
            <div *ngIf='letra_expediente == "E"'>



                <div class="row row-detalles">



                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUsers"></fa-icon>
                                | Condenado
                            </div>
                            <div class="card-body">
                                <div *ngIf='!showCondenadoCard' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='showCondenadoCard'>
                                    
                                        <ul *ngFor="let conde of roles" class="ul-cards">
                                            <div *ngIf="conde.rol=='Condenado'">
                                            <li>
                                                <strong>{{conde.persona.apellidos}} {{conde.persona.nombres}} </strong>
                                            </li>
                                            <li>
                                                <i> D.N.I.: </i> {{conde.persona.numero_documento}}
                                            </li>
                                            <li>
                                               <i>Situación personal:</i> {{conde.situacion_personal}}
                                            </li>
                                            <li>
                                                <i>Situacion procesal: </i>{{conde.situacion_procesal}}
                                            </li>
                                            <li>
                                                <i>Complejo: </i>{{conde.complejo_de_detencion}}
                                            </li>
                                            <li>
                                                <i>Prontuario: </i>{{conde.prontuario}}
                                            </li> 
                                        </div>                                           
                                        </ul>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Datos de la Condena
                            </div>
                            <div class="card-body">
                                <div *ngIf='!showDatosDeCondenaCard' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='showDatosDeCondenaCard'>
                                    <ul *ngFor="let datoC of datosCondenas" class="ul-cards">
                                        <li>
                                            <strong>Tipo de condena: </strong>{{datoC.tipo_condena}}
                                        </li>
                                        <li>
                                            <strong>Fecha de cumplimiento: </strong>{{datoC.fecha_de_cumplimiento}}
                                        </li>
                                        <li>
                                            <strong>Tiempo Restante: </strong><span>{{datoC.finaliza_fecha_cumplimiento}}</span>
                                        </li>
                                        <li *ngIf="datoC.reincidencia">
                                            <strong style="color: red;">Reincidente</strong>
                                        </li>
                                        <hr>
                                        <li><strong>Ley Aplicable N°: </strong>{{datoC.ley}}</li>
                                        <li *ngIf="datoC.fecha_libertad_condicional">
                                            <strong >Plazo art. 13 C.P. - Libertad condicional: </strong>
                                            {{datoC.fecha_libertad_condicional}}
                                        </li>                  
                                        <li *ngIf="datoC.sentencia_numero_sentencia">
                                            <hr>
                                            <strong>Sentencia N°: </strong>{{datoC.sentencia_numero_sentencia}}{{ datoC.sentencia__organismo}}
                                        </li>                                                              
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <div class="row">
                                    <div class="col-md-12 col-xl-6">
                                        <fa-icon [icon]="faCalendarCheck"></fa-icon>
                                        | Audiencias
                                    </div>
                                    <div class="col-md-12 col-xl-6" *ngIf="!isLoading_informacionGeneral && audiencias_pasadas.length!=0">
                                        <!-- Button trigger modal -->
                                        <button type="button" (click)="showDialog()"
                                            class="btn btn-outline-primary btn-sm"
                                            >
                                            Audiencias pasadas
                                            <fa-icon [icon]="faEye"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='audiencias_futuras.length >0 || showPagination'>
                                    <ul *ngFor="let proximaAudiencia of proximasAudiencias" class="ul-cards">
                                        <li><strong> Hora: </strong>{{proximaAudiencia.audiencia.hora_audiencia}} </li>
                                        <li><strong> Tipo: </strong>{{proximaAudiencia.audiencia.tipo}} </li>
                                        <li><strong> Sala: </strong>{{proximaAudiencia.audiencia.sala}}</li>
                                        <!--<li *ngFor="let juez of audiencia"><strong> Juez: </strong>{{audiencia.juez}}</li>-->
                                        <li><strong> Juez: </strong>{{proximaAudiencia.juez}}</li>
                                        <li><strong> Fiscal: </strong>{{proximaAudiencia.fiscal}}</li>
                                        <li><strong> Defensor: </strong>{{proximaAudiencia.defensor}}</li>

                                        <li>
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card">
                                                            <div class="card-body">  
                                                                <div class="hori-timeline" dir="ltr" style="border-top: 3px solid #e9ecef;">
                                                                    <ul class="list-inline events" width="100%;">
                                                                    
                                                                    <li class="list-inline-item event-list">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{fechaActual}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-12">Hoy<br></h6>
                                                                        </div>
                                                                        </li> 
                                                                        <li class="list-inline-item event-list" style="float:right;">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{proximaAudiencia.fecha}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-16">Audiencia<br></h6> 
                                                                            <!-- <span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.dias > 0, else horas">{{proximaAudiencia.tiempoAudiencia.dias}} dias</span>
                                                                            <ng-template #horas><span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.horas > 0">{{proximaAudiencia.tiempoAudiencia.horas}} horas</span></ng-template>
                                                                            </h6> -->
                                                                        </div>
                                                                        </li>
                                                                    </ul>
                                                                    </div>  
                                                            </div>
                                                        </div>
                                                        <!-- end card -->
                                                    </div>
                                                </div>
                                            </div> 
                                        </li>
                                    </ul> 
                                    <div *ngIf='audiencias_futuras.length>2 || showPagination' class="col-md-12 col-xl-12" >
                                        <br>
                                        <p class="card-text" style="color: red">Existen más audiencias agendadas</p>
                                        <a 
                                            (click)="showDialogFuturas()"
                                            class="btn btn-primary"
                                        >Ver todas
                                        <fa-icon [icon]="faEye"></fa-icon>
                                        </a>                                            
                                    </div>                                        
                            </div>
                            <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (audiencias_futuras.length == 0 && !showPagination) && (!isLoading_informacionGeneral && audiencias_pasadas!=0)'>
                                <div>No existen audiencias futuras en Agenda.
                                    <br> Puede ver audiencias pasadas presionando "Audiencias pasadas"
                                </div>
                            </div>
                            <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (!isLoading_informacionGeneral && audiencias_pasadas==0)'>
                                <div>No existen audiencias para este expediente
                                </div>
                            </div>
                            
                                
                            </div>
                        </div>
                    </div>

                </div>




                <div class="row row-detalles">
                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Sentencias
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul *ngFor="let sentencia of sentencias" class="ul-cards">
                                        <div *ngIf="ejecuciones">
                                            <div class="card" style="background-color: #f8f9fa!important;">
                                                <li *ngFor="let montoPena of ejecuciones">
                                                    <div *ngFor="let pdf of pdfsSentencia">
                                                        <td >
                                                            
                                                      <!--       <div class="col-md-12 col-xl-6">
                                                                <button type="button" (click)="showDialog()"
                                                                    class="btn btn-outline-primary btn-sm">
                                                                    Audiencias pasadas
                                                                    <fa-icon [icon]="faEye"></fa-icon>
                                                                </button>
                                                            </div>  -->                                               
                                                            
                                                            
                                                            
                                                            <a  role="button"
                                                                (click)="loadSelected(pdf.id)" ><fa-icon [icon]="faFilePdf"></fa-icon>
                                                            </a>                                                                               
                                                        </td>
                                                        <td><h6><span *ngIf="montoPena.concepto=='Pena Única', else noUnifica"> Unificación </span>
                                                            <ng-template #noUnifica>Pena</ng-template>
                                                            por Sentencia N° {{montoPena.sentencia}} ({{sentencia.organismo}})</h6> 
                                                            ({{montoPena.anio}}A - {{ montoPena.mes}}M - {{montoPena.dia}}D)                                               
                                                        </td> 
                                                    </div>                                          
                                                </li>
                                            </div>
                                            <hr>
                                            <li>
                                                <strong>- Sentencia N°: </strong>{{sentencia.sentencia}} ({{ sentencia.organismo}})
                                                
                                            </li> 
                                            <li *ngFor="let montoPena of ejecuciones">
                                                <strong *ngIf="montoPena.concepto == 'Condena'">Condena </strong>                                            
                                                {{montoPena.anio}}A - {{ montoPena.mes}}M - {{montoPena.dia}}D
                                            </li> 
                                            <hr>
                                        </div>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Legajos vinculados
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <div *ngFor="let vinculado of vinculadosHC">
                                        <a  href="javascript:void(0)" (click)="navigate2Detail(vinculado.anio,vinculado.letra,vinculado.numero); false" target="_blank" style="color: blue"> 
                                            - {{vinculado.legajo_asociado}}
                                        </a>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Defensa técnica
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul *ngFor="let defensor of defensores" class="ul-cards">
                                        <li>
                                            <strong>{{defensor.persona}}: </strong>
                                        </li>
                                        <li *ngFor="let defensa of defensor.defensores">
                                            <em *ngIf='defensa.tipo != "particular" ; else particular'>
                                                Defensor oficial: </em> {{defensa.defensor}}
                                            <ng-template #particular>
                                                <em>Defensor particular: </em> {{defensor.defensor}}
                                            </ng-template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Evolucion de la Condena
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>






                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faStreetView"></fa-icon>
                                | Víctimas
                            </div>
                            <div class="card-body" style="margin-top: 1rem;">
                                <p-table [value]="victimas" class="myTable"
                                    *ngIf="victimas.length > 0, else sinVictimas" styleClass="p-datatable-responsive-demo">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Ver</th>
                                            <th>Apellido y nombre</th>
                                            <th>Número de documento</th>
                                            <th>Teléfono</th>
                                            <th>Observaciones</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td><span class="p-column-title">Ver: </span>
                                                <!-- Button trigger modal -->
                                                <button type="button"  
                                                    [disabled]="buttonPersona"                                                                                                                                                     
                                                    (click)="verPersona(item.persona.numero_documento); this.disabled=true;"
                                                    class="btn btn-outline-primary btn-sm">
                                                    <fa-icon [icon]="faAddressBook"></fa-icon>
                                                </button> </td>

                                            <td><span class="p-column-title">Apellido y nombre: </span>
                                                {{item.persona.apellidos}}{{item.persona.nombres}}</td>
                                            <td><span class="p-column-title">Número de documento: </span>
                                                {{item.persona.tipo_documento}} {{item.persona.numero_documento}}</td>                                            
                                            <td><span class="p-column-title">Teléfono: </span>
                                                {{item.persona.telefono1}}</td>
                                            <td><span class="p-column-title">Observaciones: </span>
                                                {{item.campo_abierto}}</td>                                                                                            
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <ng-template #sinVictimas>No existen víctimas cargados</ng-template>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- FIN FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FIN FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FIN FILA EXCLUSIVA SI ES EJECUCION -->
            <!-- FIN FILA EXCLUSIVA SI ES EJECUCION -->


            <!--  FILA EXCLUSIVA SI ES GENERAL -->
            <!--  FILA EXCLUSIVA SI ES GENERAL -->
            <!--  FILA EXCLUSIVA SI ES GENERAL -->
            <!--  FILA EXCLUSIVA SI ES GENERAL -->
            <!--  FILA EXCLUSIVA SI ES GENERAL -->

            <div *ngIf="letra_expediente != 'E' && letra_expediente != 'HC'">

                <div class="row row-detalles">
                    <div class="col-md-4">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUsers"></fa-icon>
                                | Acusación
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_Principal' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_Principal'>
                                    <ul class="ul-cards">
                                        <li><strong>Unidad Fiscal: </strong>{{expediente.unidad_fiscal}}</li>
                                        <li><strong>Fiscalia: </strong>{{expediente.fiscalia}}</li>
                                        <li><strong>Fiscal: </strong>{{expediente.fiscal}}</li>
                                        <li *ngIf="expediente.asesoria != null"><strong>Asesoria: </strong>{{expediente.asesoria}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <fa-icon [icon]="faUserShield"></fa-icon>
                                | Defensa técnica
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul *ngFor="let defensor of defensores" class="ul-cards">
                                        <li>
                                            <strong>{{defensor.persona}}: </strong>
                                        </li>
                                        <li *ngFor="let defensa of defensor.defensores">
                                            <em *ngIf='defensa.tipo != "particular" ; else particular'>
                                                Defensor oficial: </em> {{defensa.defensor}}
                                            <ng-template #particular>
                                                <em>Defensor particular: </em> {{defensor.defensor}}
                                            </ng-template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <div class="row">
                                    <div class="col-md-12 col-xl-6">
                                        <fa-icon [icon]="faCalendarCheck"></fa-icon>
                                        | Audiencias
                                    </div>
                                    <div class="col-md-12 col-xl-6" *ngIf="!isLoading_informacionGeneral && audiencias_pasadas.length!=0">
                                        <!-- Button trigger modal -->
                                        <button 
                                            type="button" 
                                            (click)="showDialog()"
                                            class="btn btn-outline-primary btn-sm"
                                        >
                                            Audiencias pasadas
                                            <fa-icon [icon]="faEye"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='audiencias_futuras.length >0 || showPagination'>
                                    <ul *ngFor="let proximaAudiencia of proximasAudiencias" class="ul-cards">
                                        <li><strong> Hora: </strong>{{proximaAudiencia.audiencia.hora_audiencia}} </li>
                                        <li><strong> Tipo: </strong>{{proximaAudiencia.audiencia.tipo}} </li>
                                        <li><strong> Sala: </strong>{{proximaAudiencia.audiencia.sala}}</li>
                                        <!--<li *ngFor="let juez of audiencia"><strong> Juez: </strong>{{audiencia.juez}}</li>-->
                                        <li><strong> Juez: </strong>{{proximaAudiencia.juez}}</li>
                                        <li><strong> Fiscal: </strong>{{proximaAudiencia.fiscal}}</li>
                                        <li><strong> Defensor: </strong>{{proximaAudiencia.defensor}}</li>

                                        <li>
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <div class="card">
                                                            <div class="card-body">  
                                                                <div class="hori-timeline" dir="ltr" style="border-top: 3px solid #e9ecef;">
                                                                    <ul class="list-inline events" width="100%;">
                                                                    
                                                                    <li class="list-inline-item event-list">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{fechaActual}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-12">Hoy<br></h6>
                                                                        </div>
                                                                        </li> 
                                                                        <li class="list-inline-item event-list" style="float:right;">
                                                                        <div class="px-6">
                                                                            <div class="event-date bg-soft-primary">
                                                                            <span style="font-size:10px;">{{proximaAudiencia.fecha}}</span>
                                                                            </div>
                                                                            <h6 class="font-size-16">Audiencia<br></h6>
                                                                            <!-- <span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.dias > 0, else horas">{{proximaAudiencia.tiempoAudiencia.dias}} dias</span>
                                                                            <ng-template #horas><span style="font-size:10px;" *ngIf="audiencia.tiempoAudiencia.horas > 0">{{proximaAudiencia.tiempoAudiencia.horas}} horas</span></ng-template>
                                                                            </h6> -->
                                                                        </div>
                                                                        </li>
                                                                    </ul>
                                                                    </div>  
                                                            </div>
                                                        </div>
                                                        <!-- end card -->
                                                    </div>
                                                </div>
                                            </div> 
                                        </li>
                                    </ul>                                    
                                        <div *ngIf='audiencias_futuras.length>2 || showPagination' class="col-md-12 col-xl-12" >
                                            <br>
                                            <p class="card-text" style="color: red">Existen más audiencias agendadas</p>
                                            <a 
                                                (click)="showDialogFuturas()"
                                                class="btn btn-primary"
                                            >Ver todas
                                            <fa-icon [icon]="faEye"></fa-icon>
                                            </a>                                            
                                        </div>                                        
                                </div>
                                <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (audiencias_futuras.length == 0 && !showPagination) && (!isLoading_informacionGeneral && audiencias_pasadas!=0)'>
                                    <div>No existen audiencias futuras en Agenda.
                                        <br> Puede ver audiencias pasadas presionando "Audiencias pasadas"</div>
                                </div>
                                <div *ngIf='(!isLoading_informacionGeneral && audiencias_futuras.length == 0) && (!isLoading_informacionGeneral && audiencias_pasadas==0)'>
                                    <div>No existen audiencias para este expediente
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-detalles">
                    <div class="col-md-3">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faBalanceScale"></fa-icon>
                                | Delitos 
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <ul *ngFor="let delito of delitos" class="ul-cards">
                                        <li><strong> {{delito.persona}}: </strong> </li>
                                        <li *ngFor="let del of delito.delitos">• {{del.tipo}}: {{del.delito}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9  card-siguiente">
                        <div class="card card-detalles  ">
                            <div class="card-header"  >
                                <fa-icon [icon]="faCommentDots"></fa-icon>
                                | Hechos
                            </div>
                            <div class="card-body">
                                <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary"
                                    role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div *ngIf='!isLoading_informacionGeneral'>
                                    <li class="list-group-item no-line" *ngIf='hechos.visibilidad, else noVisible'>
                                        Fecha del Hecho: {{hechos.fecha}}
                                        <hr>
                                        {{hechos.hechos}}
                                        <ng-template #noVisible>
                                            <p class="text-primary">Hechos no visibles</p>
                                        </ng-template>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faMale"></fa-icon>
                                | Imputados
                            </div>
                            <div class="card-body" style="margin-top: 1rem;">
                                <!--
                                    <p-table #tt [columns]="selectedColumns" [value]="tableData" dataKey="id" editMode="row" [(first)]="first" [loading]="loading" [globalFilterFields]="_completeFilterFields" [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [(selection)]="_selectedColumns" [pageLinks]=5
                                    [rowsPerPageOptions]="[10,20,30,40,50,totalRecords]" [rowHover]=true [sortField]=_sortField [responsive]="true" [autoLayout]="true">
                                -->
                                <p-table [value]="imputados" styleClass="p-datatable-responsive-demo" class="myTable" *ngIf="imputados, else sinImputados">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Ver</th>
                                            <th>Nomb. y apell.</th>
                                            <th>Nro. de docu.</th>
                                            <th>Sit. personal</th>
                                            <th>Sit. procesal</th>
                                            <th>Fecha aprehensión</th>
                                            <th>Observaciones</th>
                                            <th>Ant. Penitenc.</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td><span class="p-column-title">Ver: </span>
                                                <!-- Button trigger modal -->
                                                <button type="button"
                                                    [disabled]="buttonPersona"                                                                                                                                                     
                                                    (click)="verPersona(item.persona.numero_documento); this.disabled=true;"
                                                    class="btn btn-outline-primary btn-sm">
                                                    <fa-icon [icon]="faAddressBook"></fa-icon>
                                                </button>
                                            </td>
                                            <td><span class="p-column-title">Apellido y nombre: </span>
                                                {{item.persona.apellidos}}, {{item.persona.nombres}}</td>
                                            <td><span class="p-column-title">Número de documento: </span>
                                                {{item.persona.tipo_documento}} {{item.persona.numero_documento}}</td>
                                            <td><span class="p-column-title">Situación personal: </span>
                                                <span *ngIf="alertaImputados; else noDetenido" style="color: red; font-style: oblique;">
                                                    <fa-icon [icon]="faExcTriangle" style="color: red;"></fa-icon> {{item.situacion_personal}}</span>                                                    
                                                <ng-template #noDetenido><span>{{item.situacion_personal}}</span></ng-template>
                                            </td>
                                                <td><span class="p-column-title">Situación procesal: </span>
                                                {{item.situacion_procesal}}</td>
                                            <td><span class="p-column-title">Fecha aprehensión: </span>
                                                {{item.fecha_de_aprehension}}</td>
                                            <td ><span class="p-column-title" id="last-row">Observaciones: </span>
                                                    {{item.campo_abierto}}</td>   
                                            <td></td>                                         
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <ng-template #sinImputados>No existen imputados cargados</ng-template>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faStreetView"></fa-icon>
                                | Víctimas
                            </div>
                            <div class="card-body" style="margin-top: 1rem;">
                                <p-table [value]="victimas" class="myTable" styleClass="p-datatable-responsive-demo"
                                    *ngIf="victimas.length > 0, else sinVictimas">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Ver</th>
                                            <th>Apellido y nombre</th>                                            
                                            <th>Número de documento</th>
                                            <th>Teléfono</th>
                                            <th>Observaciones</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td><span class="p-column-title">Ver: </span>
                                                <!-- Button trigger modal -->
                                                <button type="button"
                                                [disabled]="buttonPersona"                                                                                                                                                     
                                                    (click)="verPersona(item.persona.numero_documento); this.disabled=true;"                                                    
                                                    class="btn btn-outline-primary btn-sm">
                                                    <fa-icon [icon]="faAddressBook"></fa-icon>
                                                </button> </td>
                                            <td><span class="p-column-title">Apellido y nombre: </span>
                                                {{item.persona.apellidos}}, {{item.persona.nombres}}</td>                                            
                                            <td><span class="p-column-title">Número de documento: </span>
                                                {{item.persona.tipo_documento}}{{item.persona.numero_documento}}</td>
                                            <td><span class="p-column-title">Teléfono: </span>
                                                {{item.persona.telefono1}}</td>
                                            <td><span class="p-column-title">Observaciones: </span>
                                                {{item.campo_abierto}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <ng-template #sinVictimas>No existen víctimas cargados</ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row row-detalles">
                    <div class="col-md-12">
                        <div class="card card-detalles">
                            <div class="card-header"  >
                                <fa-icon [icon]="faMicroscope"></fa-icon>
                                | Peritos
                            </div>
                            <div class="card-body" style="margin-top: 1rem;">
                                <p-table [value]="peritos" class="myTable" *ngIf="peritos.length > 0, else sinPeritos" styleClass="p-datatable-responsive-demo">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Ver</th>
                                            <th>Apellido y nombre</th>                                            
                                            <th>Número de documento</th>
                                            <th>Teléfono</th>
                                            <th>Observaciones</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-item>
                                        <tr>
                                            <td><span class="p-column-title">Ver: </span>
                                                <!-- Button trigger modal -->
                                                <button type="button"
                                                    [disabled]="buttonPersona"                                                                                                                                                     
                                                    (click)="verPersona(item.persona.numero_documento); this.disabled=true;"                                                
                                                    class="btn btn-outline-primary btn-sm">
                                                    <fa-icon [icon]="faAddressBook"></fa-icon>
                                                </button> </td>
                                            <td><span class="p-column-title">Apellido y nombre: </span>
                                                {{item.persona.apellidos}}{{item.persona.nombres}}</td>
                                            <td><span class="p-column-title">Número de documento: </span>
                                                {{item.persona.tipo_documento}} {{item.persona.numero_documento}}</td>
                                            <td><span class="p-column-title">Teléfono: </span>
                                                {{item.persona.telefono1}}</td>
                                            <td><span class="p-column-title">Observaciones: </span>
                                                {{item.campo_abierto}}</td>                                            
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <ng-template #sinPeritos>No existen peritos cargados</ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="collapse multi-collapse" id="actuaciones" data-parent="#content" *ngIf="showActualizacionesDigitalizadas"> 
            <div class="col-md-12">
                <div class="card card-body">                
                <app-actuaciones-digitalizadas [master]="filters"></app-actuaciones-digitalizadas>
                </div>
            </div>
        </div>
        <div class="collapse multi-collapse" id="ver_online" data-parent="#content" *ngIf="showViewer">
            <div class="col-md-12">
                <div class="card card-body">                    
                        <app-pdfviewer [master]='filters' ></app-pdfviewer>                    
                    </div>                
            </div>
        </div>

    </div>
</div>




<!--  FIN FILA EXCLUSIVA SI ES GENERAL -->
<!--  FIN FILA EXCLUSIVA SI ES GENERAL -->
<!--  FIN FILA EXCLUSIVA SI ES GENERAL -->

<!--  FIN FILA EXCLUSIVA SI ES GENERAL -->

<!-- %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% -->

<!-- FILA EXCLUSIVA SI ES EJECUCION -->
<!-- FILA EXCLUSIVA SI ES EJECUCION -->
<!-- FILA EXCLUSIVA SI ES EJECUCION -->
<!-- FILA EXCLUSIVA SI ES EJECUCION -->
<div *ngIf='letra_expediente == "E"'>
    
</div>

<!--Scroll Top-->
<p-scrollTop></p-scrollTop>

<!---------------------------------------------->

<!-- modal Detalle de audiencias pasadas-->
<p-dialog [(visible)]="display" [breakpoints]="{'960px': '75vw'}" [style]="{width: '80vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [closeOnEscape]="true" styleClass="header-modal">
    <ng-template pTemplate="header">
        <h5 class="text-center h5">
            <fa-icon [icon]="faCalendarCheck" style="color:white;font-size: 23px; margin-right: 1rem;"> </fa-icon>
            | Audiencias anteriores registradas
        </h5>
    </ng-template>
    <ng-template pTemplate="body" style=" margin-top: 2%;">
        <span style="font-size: 11.6px;"><strong>Audiencias de fechas pasadas</strong></span>  
        <div style="text-align: center">  
            <p-table   
                [value]="audiencias_pasadas"       
                [paginator]="true"
                [rows]="rowsPasadas"  
                [showPageLinks]="true"
                [totalRecords]="totalRecordsPasadas"
                [(first)]="firstIndex" 
                [(prev)]="previa"
                [(next)]="siguiente"
                [pageLinks]="5"
                [lazy]="true" 
                (onLazyLoad)="loadPage($event)"
                [loading]="loading" 
                [showFirstLastIcon]="true"
                class="audTable table table-bordered table-striped" styleClass="p-datatable-responsive-demo">
                <ng-template pTemplate="header" >
                    <tr>
                        <th>Fecha </th>
                        <th>Hora </th>
                        <th>Tipo </th>
                        <th>Sala </th>
                        <th>Resultado </th>
                        <th>Videos </th>                
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-audiencia >
                    <tr>
                        <td><span class="p-column-title">Fecha: </span>
                            {{audiencia.fecha_audiencia}}</td>
                        <td><span class="p-column-title">Hora: </span>
                            {{audiencia.hora_audiencia}}</td>                                            
                        <td><span class="p-column-title">Tipo: </span>
                            {{audiencia.tipo}}</td>
                        <td><span class="p-column-title">Sala: </span>
                            {{audiencia.sala}}</td>                  
                        <td><span class="p-column-title">Sala: </span>
                            {{audiencia.resultado}}</td>                
                        <td ><span class="p-column-title">Videos: </span>
                            <li  *ngFor='let video of audiencia.video'><a href="{{video.link}}" target="_blank" style="color:#1607a1;"> Videos</a></li>
                        </td>                 
                    </tr>
                </ng-template>       
            </p-table>    
        </div>  
        
            <div *ngIf="audiencias_pasadas.length==0 && !loading" class="col-md-12" style="text-align: center;">
            <span style="font-size: 12px; color: red; text-align: center;"><strong>No existen audiencias pasadas</strong></span>
            </div>
       
</ng-template>   
 
</p-dialog>  

<!-- modal Detalle de audiencias futuras-->
<p-dialog [(visible)]="audFuturas" [breakpoints]="{'960px': '75vw'}" [style]="{width: '80vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [closeOnEscape]="true" styleClass="header-modal">
    <ng-template pTemplate="header">
        <h5 class="text-center h5">
            <fa-icon [icon]="faCalendarCheck" style="color:white;font-size: 23px; margin-right: 1rem;"> </fa-icon>
            | Audiencias pŕoximas registradas
        </h5>
    </ng-template>
    <ng-template pTemplate="body" style=" margin-top: 2%;">
    <span style="font-size: 11.6px;"><strong>Audiencias próximas</strong></span>  
    <p-table         
                [paginator]="true"
                [rows]="rowsFuturas"  
                [pageLinks]="5"
                [totalRecords]="totalRecordsFuturas"
                [(first)]="firstIndex" 
                [(prev)]="previa"
                [(next)]="siguiente"
                [value]="audiencias_futuras" 
                [lazy]="true" 
                (onLazyLoad)="loadPage($event)"
                [loading]="loading" 
                [showFirstLastIcon]="true"
                class="audTable table table-bordered table-striped" styleClass="p-datatable-responsive-demo">
    
        <ng-template pTemplate="header" >
            <tr>
                <th style="width:7%">Fecha </th>
                <th style="width:7%">Hora </th>
                <th style="width:12%">Tipo </th>
                <th style="width:12%">Juez </th>
                <th style="width:12%">Fiscal </th>
                <th style="width:50%">Defensor </th>                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-audiencia >
            <tr>
                <td><span class="p-column-title">Fecha: </span>
                    {{audiencia.audiencia.fecha_audiencia}}</td>
                <td><span class="p-column-title">Hora: </span>
                    {{audiencia.audiencia.hora_audiencia}}</td>                                            
                <td><span class="p-column-title">Tipo: </span>
                    {{audiencia.audiencia.tipo}}</td>
                <td><span class="p-column-title">Juez: </span>
                    <li  *ngFor='let juez of audiencia.audiencia.juez.nombre'>{{audiencia.audiencia.juez.nombre}}</li>
                    {{audiencia.juez}}
                </td>                  
                <td><span class="p-column-title">Fiscal: </span>
                    <li  *ngFor='let fisc of audiencia.audiencia.fiscal.nombre'>{{audiencia.audiencia.fiscal.nombre}}</li>
                    {{audiencia.fiscal}}
                </td>                
                <td ><span class="p-column-title">Defensa: </span>
                    <li  *ngFor='let def of audiencia.audiencia.defensor'>{{audiencia.audiencia.defensor.nombre}}</li>
                    {{audiencia.defensor}}
                </td>                 
            </tr>
        </ng-template>
    </p-table>

</ng-template>        
</p-dialog>  

<!-- modal Detalle de personas (tablas Imputados, Peritos, Víctimas)-->
<p-dialog [(visible)]="verPers" [breakpoints]="{'960px': '75vw'}" [style]="{width: '80vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" styleClass="header-modal" (onHide)="enableButton()">

    <ng-template pTemplate="header">
        <h5 class="text-center h5">Datos personales y Antecedentes registrados de {{persona.nombres}}
            {{persona.apellidos}}</h5>
    </ng-template>
    <ng-template pTemplate="body">
        <div class="card-body">
            <div *ngIf='isLoading_informacionGeneral' class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf='!isLoading_informacionGeneral'>
                <div class="row">
                    <section class="py-3">
                        <div class="container">
                            <div class="row grid-divider">
                                <div class="col-sm-12 col-md-4 col-lg-4 my-1">
                                    <div class="card">
                                        <div class="card-body">
                                            <ul class="ul-cards ul-modal">
                                                <em>
                                                    <li>Nacionalidad: {{persona.nacionalidad}}</li>
                                                    <li>Fecha de nacimiento: {{persona.fecha_nacimiento}}</li>
                                                    <li>Lugar de nacimiento: {{persona.lugar_nacimiento}}</li>
                                                    <li>Calle: {{persona.domicilio_calle}}</li>
                                                    <li>Número: {{persona.domicilio_numero}}</li>
                                                    <li> Manzana: {{persona.domicilio_manzana}}</li>
                                                    <li>Casa: {{persona.domicilio_casa}}</li>
                                                    <li>Piso: {{persona.domicilio_piso}}</li>
                                                    <li>Departamento: {{persona.domicilio_dto}}</li>
                                                    <li>Barrio: {{persona.domicilio_barrio}}</li>
                                                    <li>Localidad: {{persona.domicilio_localidad}}</li>
                                                    <li>Departamento: {{persona.domicilio_departamento}}</li>
                                                    <li>Provincia: {{persona.domicilio_provincia}}</li>
                                                    <li>Teléfono: {{persona.telefono_1}}</li>
                                                    <li>Teléfono 2: {{persona.telefono_2}}</li>
                                                    <li>Correo electrónico: {{persona.correo_electronico}}</li>
                                                    <li>Contacto de videollamada: {{persona.contacto_videollamada}}</li>
                                                    <li>Ocupación: {{persona.ocupacion}}</li>
                                                    <li>Lugar de trabajo: {{persona.lugar_de_trabajo}}</li>
                                                </em>
                                                <hr>
                                                <li><span style="text-decoration: underline; ">Ultima
                                                        modificación</span>: {{persona.usuario_modificacion}}
                                                    ({{persona.fecha_modificacion}}) </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8 col-lg-8 my-1">
                                    <div class="card">
                                        <div class="card-body">
                                            <ul class="ul-cards ul-modal">
                                                <em>
                                                    <p><strong>
                                                            <li>Antecedentes registrados en LeD</li>
                                                        </strong>
                                                    </p>                                                                                                       
                                                    <div *ngFor='let antecedente of antecedentes'>
                                                        <!-- <div class="row">
                                                            <div class="col-md-12 col-lg-12">  -->
                                                        <span>
                                                            <p></p>
                                                        </span>
                                                        <div class="row grid-divider">
                                                            <div *ngIf='isLoading_antecedentes' class="spinner-border text-primary" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                            <div class="col-md-10" *ngIf='!isLoading_antecedentes'>
                                                                <li><em>Legajo:</em> {{antecedente.expediente.numero}}
                                                                </li>
                                                                <li><em>Organismo:</em>
                                                                    {{antecedente.expediente.organismo}}</li>
                                                                <li><em>Proceso:</em> {{antecedente.expediente.proceso}}
                                                                </li>
                                                                <li><em>Etapa:</em> {{antecedente.expediente.etapa}}
                                                                </li>
                                                                <li><em>Carátula:</em>
                                                                    {{antecedente.expediente.caratula}}</li>
                                                                <li><em>Rol:</em> {{antecedente.rol}}</li>
                                                            </div>
                                                            <div class="col-md-2">
                                                                <button type="button"
                                                                    (click)="navigate2Detail(antecedente.expediente.anio,antecedente.expediente.letra,antecedente.expediente.numero_expediente)"                                                                    
                                                                    class="btn btn-success btn-sm"> Ver
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <!-- </div>
                                                        </div> -->
                                                    </div>
                                                </em>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- modal Detalle de audiencias pasadas-->
<p-dialog [(visible)]="showPDF" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '80vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false" [closeOnEscape]="true" styleClass="header-modal">
    <ng-template pTemplate="header">
        <h5 class="text-center h5">            
            {{pdfDetail.descripcion}}
        </h5>
    </ng-template>
    <ng-template pTemplate="body" style=" margin-top: 2%;">
    
            <pdf-viewer [src]="selected" 
            [render-text]="true"
            [external-link-target]="'blank'"
            >
            </pdf-viewer> 
            
       
    </ng-template>        
</p-dialog>  
