export class HabeasCorpus {
    id;
    categoria;
    beneficiario;
    beneficiario_descripcion;
    solicitante;
    fecha_creacion;
    usuario_carga;
    fecha_modificacion;
    usuario_modificacion;
    numero;



}