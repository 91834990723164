<div class="card card-busqueda">
    <div class="row"> 
        <table class="table">	
            <thead style="border-top: none; align-items: center;">
                <tr>
                    <td>
                    <div class="row d-flex justify-content-center">
                        <div style='color:#67757c' >Número de documento</div> 
                    </div>                    
                        <div class="row d-flex justify-content-center">                                                                                         
                        <input type="text" pKeyFilter="pint" [(ngModel)]="documento">                       
                    </div>
                    </td>
                    <!-- <td>30689906</td> -->
                    <!-- <td>
                        <div>Rol</div>              
                        <p-dropdown [options]="roles" [(ngModel)]="selectedRol" placeholder="Seleccione Rol" [showClear]="true"></p-dropdown>
                    </td>
                    <td>
                        <div>Apellidos</div>                    
                        <input type="text" pInputText [(ngModel)]="apellido"> 
                        <span class="p-ml-2">{{apellido}}</span>
                    </td>
                    <td>
                        <div> Nombres</div>
                        <input id="nombre-input" type="text" pInputText [(ngModel)]="nombre" >                         
                        <span class="p-ml-2">{{nombre}}</span>
                    </td>-->
                </tr>               
            </thead>			
            <!-- <tbody>					
                <tr>
                    <td>
                        <div>Prontuario</div>                    
                        <input type="text" pInputText [(ngModel)]="prontuario"> 
                        <span class="p-ml-2">{{prontuario}}</span>
                    </td>
                    <td>
                        <div>Complejo de detención</div>              
                        <p-dropdown [options]="cities" [(ngModel)]="selectedCity" placeholder="Select a City" optionLabel="name" [showClear]="true"></p-dropdown>
                    </td>    
                    <td>                                
                        <div>Situación personal</div>              
                        <p-dropdown [options]="cities" [(ngModel)]="selectedCity" placeholder="Select a City" optionLabel="name" [showClear]="true"></p-dropdown>
                    </td>
                    <td>
                        <div>Situación procesal</div>              
                        <p-dropdown [options]="cities" [(ngModel)]="selectedCity" placeholder="Select a City" optionLabel="name" [showClear]="true"></p-dropdown>
                    </td>                    
                </tr>									
            </tbody> -->
        </table>
    </div> 

    <div class="row">
        <div class="col"><hr></div>
    </div>
    <div class="row">
        <div class="col text-right">
            <button type="button" class="btn btn-primary mt-4"  (click)="expedienteLookup()">Buscar</button>
            <button type="button" class="btn btn-secondary mt-4 ml-3 mr-5"  (click)="clearFilters()">Limpiar Filtros</button>
        </div>      
    </div>	
</div>

<app-tabla-filtro [origin]="origin" [filters]="filterParams"> </app-tabla-filtro>
   



