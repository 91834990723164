


     <div class="card-not-allowed">
        <p>  No tiene permiso para ingresar.
            Por favor contactese con ____ para solicitar acceso con su usuario.
        </p>
        <button class="btn btn-secondary card-not-alloweddash"  (click)="logout()">Volver a intentar</button>
      </div>





