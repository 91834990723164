<p-table #dt
 [value]="actuacionesDigitalizadas"
 class="audTable table table-bordered"
 [responsive]="true"
 [paginator]="true" [rows]="10"
 [globalFilterFields]="['tipo','categoria','descripcion','usuario_carga']"
 >

 <ng-template pTemplate="caption">
    <div class="p-d-flex">
        <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Busqueda Global" />
        </span>
    </div>
    </ng-template>
    <ng-template pTemplate="header" >
        <tr>
            <th>Orden </th>
            <th>Fecha de Carga </th>
            <th>Tipo </th>
            <th>Categoria </th>
            <th>Descripcion </th>
            <th>Agregado por </th>
            <th>Ver </th>                   
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-actuacion >
        <tr>
            <td> {{actuacion.orden}}</td>
            <td>{{actuacion.fecha_creacion | date:'medium'}} </td>                
            <td>{{actuacion.tipo}}  </td>
            <td> {{actuacion.categoria}} </td>
            <td> {{actuacion.descripcion}} </td>
            <td> {{actuacion.usuario_carga}} </td>
            
            <td >
                <a  role="button"
                style="color:rgb(66, 138, 52);font-size:14px;margin-right:15px"
                (click)="loadSelected(actuacion.id)">  <i class="fa fa-eye fa-lg"></i>
            </a>

            <a  role="button"
            style="color:rgb(52, 62, 138);font-size:14px;"
            (click)="downloadSelected(actuacion)">  <i class="fa fa-download fa-lg"></i>
        </a>
            </td>

           
            
                
            

        </tr>
    </ng-template>
</p-table>






<pdf-viewer [src]="selected"
              [render-text]="true"
              style="display: block;"
  ></pdf-viewer>