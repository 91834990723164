export class Rol{
    id;
    numero;
    rol;
    id_numero_id_persona;
    campo_abierto;
    situacion_personal;
    situacion_procesal;
    complejo_de_detencion;
    prontuario;
    fecha_de_aprehension;
    situacion;
    visibilidad;
    fecha_creacion;
    usuario_carga;
    fecha_modificacion;
    usuario_modificacion;
    persona;
}