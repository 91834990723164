<!-- <div>
   Estilo de la visualizacion:
   <button class="btn btn-primary" (click)="setViewStyle('magazine')">Libro(Beta)</button>
   <button class="btn btn-primary" (click)="setViewStyle('classic')">Convencional</button>
</div> -->


<div  [ngClass]="{'sticky': stickyIndex}">
   <p-tree  [value]="treeData" layout="horizontal" selectionMode="single"  (onNodeSelect)="nodeSelect($event)" ></p-tree>
</div>




<!-- 
<div *ngIf="!showClassicView"> 
   <button (click)="prevPage()">ant.</button>
   <button (click)="nextPage()">sig.</button>
   

</div> -->

<!-- <canvas id='canvasEl'></canvas> -->
<!-- <canvas id="canvasEl" width="1px" height="1px" ></canvas> -->




<!-- <div *ngIf="showMagazineView"> -->
   <!-- <div id="flipbook" [hidden]="!showMagazineView"> -->
      <!-- <div id="flipbook" [hidden]="!showMagazineView">
     
      <div *ngFor="let page of full_pics">
         <img id= "{{page.docIndex+page.pageIndex}}"  src="{{page.base64}}" width="{{vw_half}}" height="{{vh}}">
         
      </div>
  
</div> -->



<!-- </div> -->

<div class="text-center" *ngIf="showMagazineView">
   <div *ngFor="let page of currentPagesPair">
      <img src="{{page.data}}" id="m{{page.docIndex}}-{{page.pageIndex}}" width="{{vw*0.9}}" style="margin-bottom: 20px;" height="{{vw*1.269}}">
   </div>
</div>


<div class="text-center" *ngIf="showClassicView">
   <div *ngFor="let page of full_pics">
      <img src="{{page.base64}}" id="m{{page.docIndex}}-{{page.pageIndex}}" width="{{vw*0.9}}" style="margin-bottom: 20px;" height="{{vw*1.269}}">
   </div>
</div>


<!-- 
<div class="text-right" *ngIf="showClassicView">
   <div *ngFor="let page of full_pics">
     
      <button (click)="scroll(page.docIndex,page.pageIndex)">{{page.docIndex}}-{{page.pageIndex}}</button>
   </div>
</div> -->



<div class="text-center" *ngIf="showNewMagazineView">
   
      <img src="{{currentDocumentFinalPages[0].base64}}" width="{{vw_half}}" height="{{vw_half*1.5}}">
      <img src="{{currentDocumentFinalPages[1].base64}}" width="{{vw_half}}" height="{{vh}}">

  
</div>

<!-- <button (click)='loadBook()'></button> -->



<p-dialog header="Generando Compilado"
[modal]='true'
[closable]='false'
[draggable]='false'
[(visible)]="displayWorkingDialog">
   <div class="text-center">
      <p-progressSpinner></p-progressSpinner>
   </div>
  

</p-dialog>



<!-- <img [src]="img" width="200" height="300">

<img id="targetIMG" width="100" height="200" > -->


<a href="javascript:void(0);" (click)='stickyIndex=!stickyIndex' class="float-btn">
   <div class='float-btn-inside'>
      <fa-icon  [icon]="faIndent"></fa-icon>
   </div>
  
  
   </a>