import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MenuBarsComponent} from '../app/menu-bars/menu-bars.component';
import {TimelineComponent} from '../app/timeline/timeline.component';
import {ConsultaLegajoComponent} from '../app/consulta-legajo/consulta-legajo.component';
import {ConsultaPersonaComponent} from  '../app/consulta-persona/consulta-persona.component';
import {DetalleExpedienteComponent} from '../app/detalle-expediente/detalle-expediente.component';
import {PdfviewerComponent} from '../app/pdfviewer/pdfviewer.component';
import { AppAuthGuard } from './app.authguard';
import { ActuacionesDigitalizadasComponent } from './actuaciones-digitalizadas/actuaciones-digitalizadas.component';
import { NotAllowedComponent } from './not-allowed/not-allowed.component';

const routes: Routes = [
  { path: '',   redirectTo: '/dash/legajo', pathMatch: 'full' },
  {
    path: 'dash',
    component: MenuBarsComponent, // this is the component with the <router-outlet> in the template
    children: [
     
      {
        path: 'legajo', // child route path
        component: ConsultaLegajoComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      },
      {
        path: 'persona', // child route path
        component: ConsultaPersonaComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      },
      
      {
        path: 'detalle-expediente/:letra/:numero_expediente/:anio/:origin', // child route path
        component: DetalleExpedienteComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      },
      {
        path: 'timeline', // child route path
        component: TimelineComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      },
      {
        path: 'viewer', // child route path
        component: PdfviewerComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      }
      ,
      {
        path: 'actuaciones-digitalizadas', // child route path
        component: ActuacionesDigitalizadasComponent,
        canActivate:[AppAuthGuard] // child route component that the router renders
      }]
  },
  {
    path: 'not-allowed',
    component: NotAllowedComponent}
   

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AppAuthGuard]
})
export class AppRoutingModule { }
