import { Component, OnInit,Input, OnChanges, SimpleChanges,isDevMode  } from '@angular/core';
import {ActuacionDigitalizada} from '../interfaces/actuacionDigitalizada';
import {DocumentService} from '../services/document.service';
import {environment} from '../../environments/environment';
@Component({
  selector: 'app-actuaciones-digitalizadas',
  templateUrl: './actuaciones-digitalizadas.component.html',
  styleUrls: ['./actuaciones-digitalizadas.component.scss']
})
export class ActuacionesDigitalizadasComponent implements OnInit {
  @Input() master = {};
  expediente_input=''
  letra_input=''
  anio_input=''
  actuacionesDigitalizadas:ActuacionDigitalizada[]=[]
  selected:any


  constructor(private documentService:DocumentService) 
  {
   
    
    }


    ngOnChanges(changes) 
    {

//       archivo: "http://entrance.jus.mendoza.gov.ar/apirest/v1/repositorio/P/99851/11_JPC1_P9985118_Actuaciones_remitidas_por_UFI.pdf"
// categoria: "Actuaciones remitidas por UFI"
// descripcion: "PDF test3"
// es_copia: false
// fecha_creacion: "2021-06-10T10:28:33.303765-03:00"
// fecha_modificacion: "2021-06-10T10:28:33.312657-03:00"
// id: 299314
// numero: "P-99851/18"
// orden: 11
// situacion: true
// slug: "actuaciones-remitidas-por-ufi"
// usuario_carga: "DevOps"
// usuario_modificacion: null
     if(isDevMode()) console.log("changes from actuaciones digitalizadas")
     if(isDevMode()) console.log(changes)
     if(isDevMode()) console.log(this.master)
     this.documentService.getIDs(this.master['letra'],this.master['numero'],this.master['anio']).subscribe(r=>{

      if(isDevMode()) console.log("result from actuaciones digitalizadas")
      if(isDevMode()) console.log(r);
      let temp_arr=[]
      
      for(let a=0;a<r.length;a++)
      {
       let temp_obj={}
       temp_obj['archivo']=r[a]['archivo']
       temp_obj['categoria']=r[a]['categoria']['actuacion']
       temp_obj['descripcion']=r[a]['descripcion']
       temp_obj['es_copia']=r[a]['es_copia']
       temp_obj['fecha_creacion']=r[a]['fecha_creacion']
       temp_obj['fecha_modificacion']=r[a]['fecha_modificacion']
       temp_obj['id']=r[a]['id']
       temp_obj['numero']=r[a]['numero']
       temp_obj['orden']=r[a]['orden']
       temp_obj['situacion']=r[a]['situacion']
       temp_obj['tipo']=r[a]['categoria']['categoria']
       temp_obj['usuario_carga']=r[a]['usuario_carga']
       temp_obj['usuario_modificacion']=r[a]['usuario_modificacion']
       temp_arr.push(temp_obj)
      }
      temp_arr.sort(function(a, b) {
        return a.orden - b.orden;
    });
      this.actuacionesDigitalizadas=temp_arr
      if(isDevMode()) console.log(this.actuacionesDigitalizadas)
      
     })
     
  
    }

    
  
  ngOnInit(): void {
   

  }

  downloadSelected(actuacion)
  {
 this.documentService.getDocument(actuacion.id).subscribe((r: Blob)=>
 {
   if(isDevMode()) console.log(r)


   var file = new Blob([r], { type: 'application/pdf' })
   var fileURL = URL.createObjectURL(file);
   let fileNameArr=actuacion.archivo.split('/')
   let fileName=fileNameArr[fileNameArr.length-1]

// if you want to open PDF in new tab
   window.open(fileURL); 
   var a         = document.createElement('a');
   a.href        = fileURL; 
   a.target      = '_blank';
   a.download    = fileName+'.pdf';
   document.body.appendChild(a);
   a.click();
 },
 (error) => {
   if(isDevMode()) console.log('getPDF error: ',error);
 })
    


  }
  loadSelected(id)
  {
    this.documentService.getDocument(id).subscribe((r: Blob)=>
      {
        if(isDevMode()) console.log(r)
        this.selected=r
      })
   
    
   
  }

}
