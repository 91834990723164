

<div class="card card-busqueda">
    <!-- <div class="row d-flex justify-content-start">
        <div class="col-md-1 col-xl-2 col-xlg-3"></div>
        <div class="col-md-2 col-xl-1 col-xlg-1 ">
            <div>Letra</div>
            <p-dropdown [options]="letras" [(ngModel)]="selectedLetra" optionLabel="name" styleClass="p-drop1" placeholder="Letra" placeholder="Ej. P"></p-dropdown>
            
        </div> 
        <div class="col-md-4 col-xl-3 col-xlg-2">
            <div>Número</div>            
            <input [(ngModel)]="numeroExpediente"  type="text" pInputText mask="0*" placeholder="Ej. 12312">   
        </div>
        <div class="col-md-4 col-xl-3 col-xlg-2">          
                <div>Año</div>
                <input [(ngModel)]="anio"    type="text" mask="0000" pInputText placeholder="Ej. 2021" >               
        </div>            
               
        
        <div class="col-md-1 col-xl-3 col-xlg-4"></div>
    </div> -->

    <div class="d-md-inline-flex flex-row justify-content-center">
        <div class="p-2"><div>Letra</div>
        <p-dropdown [options]="letras" [(ngModel)]="selectedLetra" optionLabel="name" styleClass="p-drop1" placeholder="Letra" placeholder="Ej. P"></p-dropdown>
        </div>
        <div class="p-2"><div>Número</div>            
        <input [(ngModel)]="numeroExpediente"  type="text" pInputText mask="0*" placeholder="Ej. 12312">   </div>
        <div class="p-2"> <div>Año</div>
        <input [(ngModel)]="anio"    type="text" mask="0000" pInputText placeholder="Ej. 2021" > </div>
      </div>
         <!-- <div class="col">
                    <div>Caratula contiene</div>
                    <input  [(ngModel)]="caratulaExpediente" type="text" pInputText> 
                 </div>
                 <div class="col ">
                    <div>Organismo</div>
                <p-dropdown placeholder="Organismos" [options]="organismos" [(ngModel)]="selectedOrganismo" optionLabel="name" styleClass="p-drop1"></p-dropdown>
                </div>  
                <div class="col">
                    <div>Proceso</div>
                <p-dropdown placeholder="Procesos" [options]="procesos" [(ngModel)]="selectedProceso" optionLabel="name"></p-dropdown>
                </div>  
                <div class="col">
                    <div>Numero anterior contiene</div>
                    <input [(ngModel)]="numeroAnteriorExpediente"  type="text" pInputText> 
                </div> -->
                <!-- <div class="col">
                    <div>Etapa</div>
                <p-dropdown placeholder="Etapas"  [options]="etapas" [(ngModel)]="selectedEtapa" optionLabel="name"></p-dropdown>
                </div> -->
                <!-- HC-101/21
                E-1/21
                P-99580/20 
                R-49912/20 -->

        <div class="row">
            <div class="col"><hr></div>
        </div>
        <div class="row">
            <div class="col text-right">
                <button type="button" class="btn btn-primary mt-4"  (click)="expedienteLookup()">Buscar</button>
                <button type="button" class="btn btn-secondary mt-4 ml-3 mr-5"  (click)="clearFilters()">Limpiar Filtros</button>
            </div>
          
        </div>
   

</div>
<app-tabla-filtro [origin]="origin" [filters]="filterParams"> </app-tabla-filtro>
   

